import * as React from 'react';

import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { sharedStyles } from './shared';

import type { TypographyProps } from './types';

const useStyles = makeStyles<TypographyProps>()((_, props) => {
  return {
    root: {
      ...sharedStyles(props),
      fontSize: 32,
      lineHeight: '40px',
    },
  };
});

const H1 = React.forwardRef(({ className, ...rest }: TypographyProps, ref) => {
  const { classes, cx } = useStyles(rest);
  // @ts-ignore
  return <Typography variant="h1" className={cx(classes.root, className)} ref={ref} {...rest} />;
});

export default H1;
