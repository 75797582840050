import * as React from 'react';

type MarketingSiteLinkProps = {
  to?: string;
  className?: string;
  target?: string;
  children?: React.ReactNode;
  'data-testid'?: string;
};

const MarketingSiteLink = ({
  to = '',
  className,
  target,
  children,
  'data-testid': testId,
}: MarketingSiteLinkProps) => {
  const marketingSiteUrl = new URL(to, 'https://www.reibus.com');
  return (
    <a href={marketingSiteUrl.href} className={className} target={target} data-testid={testId}>
      {children}
    </a>
  );
};

export default MarketingSiteLink;
