import UserService from '../../services/UserService';

const userService = new UserService();
class CompanyService {
  getMyAccounts = async params => {
    const limit = params.rowsPerPage;
    const offset = params.page * params.rowsPerPage;
    const sortBy = params.sortBy;
    const filter = params.filter;

    const queryParams = {
      limit,
      offset,
      sortBy: sortBy || undefined,
      filter: filter || undefined,
    };

    const queryString = Object.keys(queryParams)
      .filter(key => queryParams[key])
      .map(key => `${key}=${queryParams[key]}`)
      .join('&');

    return userService.getCompanies(queryString).then(({ data }) => {
      return data.map(account => {
        return {
          ...account,
          companyName: account.name,
          billingContactPhone: account.billingContactPhone,
          billingEmail: account.billingContactEmail,
        };
      });
    });
  };

  getCompany = companyId => {
    return userService.getCompany(companyId);
  };

  postCompany = data => {
    return userService.createCompany(data);
  };

  putCompany = (companyId, data) => {
    return userService.updateCompany(companyId, data);
  };

  publishCompany = companyId => {
    return userService.publishCompany(companyId);
  };

  postLocation = async (companyId, newLocation) => {
    try {
      const { data } = await userService.createCompanyLocation(companyId, newLocation);
      return data;
    } catch (error) {
      if (error[0]?.message) {
        throw new Error(error?.errors[0]?.message);
      }

      throw new Error('There was a problem adding the location. Try again.');
    }
  };

  putLocation = (companyId, locationId, data) => {
    return userService
      .updateCompanyLocation(companyId, locationId, data)
      .then(({ data }) => {
        return data;
      })
      .catch(error => {
        if (error?.response?.data?.errors[0]?.message) {
          throw new Error(error?.response?.data?.errors[0]?.message);
        } else {
          throw new Error('There was a problem updating the location. Try again.');
        }
      });
  };

  deleteLocation = (companyId, locationId) => {
    return userService.deleteCompanyLocation(companyId, locationId);
  };

  postProductUpdate = (companyId, productUpdateData) => {
    return userService.updateCompanyProductUpdates(companyId, productUpdateData);
  };

  deleteCompany = companyId => {
    return userService
      .deleteCompany(companyId)
      .then(({ data }) => {
        return data;
      })
      .catch(error => {
        console.error(error);
        throw new Error('There was a problem deleting the company. Try again.');
      });
  };

  getTMSCompaniesByName = searchName => {
    return userService.getTMSCompaniesByName(searchName);
  };
}

const companyService = new CompanyService();
export default companyService;
