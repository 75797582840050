import { AxiosInstance } from 'axios';

import axiosInstance from './axios';
import urls from './Urls';

class BaseService {
  urls = urls;

  getServiceInstance(): AxiosInstance {
    return axiosInstance;
  }

  replaceUrlParams(url: string, params) {
    const finalUrl = Object.entries(params).reduce((parameterizedString, [key, value]) => {
      return parameterizedString.replace(key, `${value}`);
    }, url);

    return finalUrl;
  }
}

export default BaseService;
