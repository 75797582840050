import * as React from 'react';

import { match } from '@reach/router/lib/utils';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import singleSpaReact from 'single-spa-react';

import BUILD from 'build.json';

import Root from './marketplace-root';

declare global {
  interface Window {
    reibus?: {
      marketplace?: {
        version: string;
      };
    };
  }
}

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  renderType: 'createRoot',
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    Sentry.captureException(err);
    return null;
  },
});

const reibusVersionInit = async () => {
  window.reibus = window.reibus || {};
  window.reibus.marketplace = {
    version: BUILD.VERSION,
  };
};

const enableForProduction = () => {
  return process.env.ENVIRONMENT == 'production' ? 1.0 : 0.0;
};

const sentryMount = async () => {
  const templatedRoutes = [
    '/app/dashboard/response/:id',
    '/app/dashboard/company/profile/:id',
    '/app/dashboard/company/locations/:id',
    '/app/dashboard/company/members/:id',
    '/app/dashboard/members/:id',
    '/app/dashboard/requests-for-quote/:id',
    '/app/listings/:id/negotiations',
    '/app/listings/management/company/:id',
    '/show/listings/:tagNumber',
  ];

  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.IS_LOCAL ? 'local' : process.env.ENVIRONMENT,
    tracesSampleRate: enableForProduction(),
    replaysSessionSampleRate: enableForProduction(),
    replaysOnErrorSampleRate: enableForProduction(),
    profilesSampleRate: enableForProduction(),
    release: process.env.RELEASE,
    integrations: [
      new Sentry.BrowserTracing({
        idleTimeout: 5000,
        tracePropagationTargets: [process.env.API_URL!, /^\//],
        beforeNavigate: context => {
          // eslint-disable-next-line no-restricted-globals
          const path = location.pathname;
          const matchingTemplatedRoute = templatedRoutes.find(route => match(route, path));
          return {
            ...context,
            name: matchingTemplatedRoute ?? path,
          };
        },
      }),
      // new Sentry.Replay(),
      new Sentry.BrowserProfilingIntegration(),
    ],
  });
};

const sentryUnmount = async () => {
  return Sentry.close();
};

export const bootstrap = [reibusVersionInit, lifecycles.bootstrap];
export const mount = [sentryMount, lifecycles.mount];
export const unmount = [sentryUnmount, lifecycles.unmount];
