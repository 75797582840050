import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import SPINNER from './assets/zr6o81jj3gslpnua59tg.png';
const useStyles = makeStyles()(() => ({
  loader: {
    '@keyframes spin': {
      from: {
        transform: 'rotate(0deg)',
      },
      to: {
        transform: 'rotate(360deg)',
      },
    },
    animation: 'spin 1.5s linear infinite',
  },
  label: {
    position: 'relative',
  },
}));

type BlueSteelSpinnerProps = {
  showLabel?: boolean;
  position?: 'bottom' | 'right';
  text?: string;
  fontSize?: string | number;
  spinnerWidth?: string | number;
  spinnerHeight?: string | number;
};

export const BlueSteelSpinner = ({
  showLabel = false,
  position = 'bottom',
  text,
  fontSize = '.875rem',
  spinnerHeight = '2.5rem',
  spinnerWidth = '2.5rem',
}: BlueSteelSpinnerProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation(['listing-details']);
  const direction = position === 'bottom' ? 'column' : 'row';
  return (
    <Grid container justifyContent="center" alignItems="center" direction={direction}>
      <Grid
        item
        data-testid="loading-spinner"
        src={SPINNER}
        component="img"
        alt="loading-spinner"
        className={classes.loader}
        width={spinnerWidth}
        height={spinnerHeight}
      />
      {showLabel ? (
        <Grid item>
          <Typography
            component="p"
            padding=".4rem"
            fontWeight={400}
            fontSize={fontSize}
            color="#212224"
            textTransform="capitalize"
            className={classes.label}
          >
            {text || t('loading')}
          </Typography>
        </Grid>
      ) : null}
    </Grid>
  );
};
