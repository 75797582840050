import { useState, useEffect } from 'react';

import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Dialog, IconButton } from '@mui/material';
import { navigate } from '@reach/router';
import { subDays } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { useUpdateUserPrompt } from 'components/dashboard/hooks/useUpdateUserPrompt';
import Button from 'components/library/atoms/Button';
import { Body, H2 } from 'components/library/typography';
import { useAuthContext } from 'context/hooks';
import { useFlags } from 'utils/launchDarkly';
import { trackEvent } from 'utils/mixpanel';

import type { NotificaitonPreference, UserPrompt } from 'context/types';

const useStyles = makeStyles()(({ spacing }) => ({
  paper: {
    width: 440,
    borderRadius: '8px !important',
    backgroundColor: '#fff',
    padding: spacing(3),
  },
  iconButton: {
    padding: 0,
  },
  warning: {
    color: '#FFB507',
    fontSize: 32,
    marginBottom: spacing(2),
  },
  body: {
    textAlign: 'center',
    marginTop: spacing(1),
  },
  button: {
    width: '100%',
    marginTop: spacing(5),
  },
}));

const checkHasMatchNotificationsEnabled = (notificationPreferences: NotificaitonPreference[]) => {
  return notificationPreferences.some(
    notification =>
      notification.enabled &&
      notification.platform === 'EMAIL' &&
      (notification.name === 'PRODUCT_LINE_RFQ_MATCH' ||
        notification.name === 'PRODUCT_LINE_LISTING_MATCH')
  );
};

const checkHasSeenMatchNotificationsPrompt = (userPrompts: UserPrompt[]) => {
  return !!userPrompts?.find(userPrompt => {
    const { prompt, lastSeen } = userPrompt;

    const sixtyDaysAgo = subDays(new Date(), 60);
    const lastSeenLessThanSixtyDaysAgo = new Date(lastSeen) > sixtyDaysAgo;

    return lastSeenLessThanSixtyDaysAgo && prompt === 'MATCH_NOTIFICATIONS';
  });
};

const NotificationsPrompt = () => {
  const { classes } = useStyles();
  const { t } = useTranslation('account-settings');

  const { isLoggedIn, currentUser } = useAuthContext();
  const { rm14798NotificationsPrompt } = useFlags();
  const { mutate: updateUserPrompt } = useUpdateUserPrompt();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isLoggedIn && currentUser && rm14798NotificationsPrompt) {
      const { UserNotificationPreferences, UserPrompts } = currentUser;

      const hasMatchNotificationsEnabled = checkHasMatchNotificationsEnabled(
        UserNotificationPreferences
      );
      const hasSeenMatchNotificationsPrompt = checkHasSeenMatchNotificationsPrompt(UserPrompts);
      const shouldShowMatchPrompt =
        !hasMatchNotificationsEnabled && !hasSeenMatchNotificationsPrompt;

      if (!open && shouldShowMatchPrompt) {
        setOpen(true);
      }
    }
  }, [isLoggedIn, currentUser, rm14798NotificationsPrompt]);

  const handleManageClick = () => {
    setOpen(false);
    updateUserPrompt('MATCH_NOTIFICATIONS');
    trackEvent('Notifications Prompt: Manage Notification Preferences Clicked');
    navigate('/app/dashboard/account?selected=notifications');
  };

  const handleClose = () => {
    setOpen(false);
    trackEvent('Notifications Prompt: Modal Closed');
    updateUserPrompt('MATCH_NOTIFICATIONS');
  };

  return (
    <Dialog open={open} onClose={handleClose} classes={{ paper: classes.paper }}>
      <Box display="flex" justifyContent="flex-end">
        <IconButton onClick={handleClose} className={classes.iconButton}>
          <FontAwesomeIcon icon={faXmark} />
        </IconButton>
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <FontAwesomeIcon icon={faTriangleExclamation} className={classes.warning} />
        <H2>{t('notificationsPrompt.manage')}</H2>
        <Body className={classes.body}>{t('notificationsPrompt.remainUpdated')}</Body>
        <Button
          onClick={handleManageClick}
          label={t('notificationsPrompt.managePreferences')}
          className={classes.button}
        />
      </Box>
    </Dialog>
  );
};

export default NotificationsPrompt;
