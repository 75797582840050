import { getApiRoot } from '../utils/apiUtils';

const baseUrl = getApiRoot();

const urls = {
  // Price Conversion
  CONVERT_CURRENCY: `${baseUrl}/payments/convert-currency`,

  // Lots - Listing
  BASE_CRUD_USER_LISTING: `${baseUrl}/listings`,
  BASE_CRUD_USER_LOTS: `${baseUrl}/lots`,
  GET_FEATURED_LISTINGS: `${baseUrl}/listings/featured`,

  // Listings Management
  GET_ALL_LISTINGS: `${baseUrl}/listings/management`,
  GET_COMPANY_LISTINGS: `${baseUrl}/listings/management/company/:companyId/listing`,
  GET_LISTING_PRICES: `${baseUrl}/listings/management/company/:companyId/listing/:listingId/price`,
  DELETE_LISTINGS: `${baseUrl}/listings/management/company/:companyId/listing`,
  UPDATE_LISTINGS_AVAILABILITY: `${baseUrl}/listings/management/company/:companyId/listing/status`,
  UPDATE_LISTINGS_FIELDS: `${baseUrl}/listings/management/company/:companyId/listing`,
  UPDATE_LISTING_PRICES: `${baseUrl}/listings/management/company/:companyId/listing/:listingId/price`,
  CREATE_LISTING_UPLOAD_URL: `${baseUrl}/listings/management/company/:companyId/listing/upload`,

  // Listing - Document Request
  LISTING_DOCUMENT_REQUEST: `${baseUrl}/listings/:listingId/documentRequests`,

  // Listing - Send a new question
  LISTING_SEND_QUESTION: `${baseUrl}/listings/:listingId/question`,

  // Dashboard
  GET_DASHBOARD_SUMMARY: `${baseUrl}/users/dashboard/summary`,

  // User's preferred currency
  GET_EXCHANGE_RATE: `${baseUrl}/payments/convert-currency`,

  // Products Supplied
  GET_USER_PRODUCT_LINE: (userId: string) => `${baseUrl}/products-supplied/users/${userId}`,
  BASE_CRUD_PRODUCT_LINE: `${baseUrl}/products-supplied`,

  // Admin
  GET_ADMIN_MARKETPLACE_LISTING: `${baseUrl}/admin/listings`,
  GET_AWS_MARKETPLACE_ADMIN_LISTINGS: `${baseUrl}/admin/listings?allLots=`,
  GET_ADMIN_LISTING: `${baseUrl}/admin/listings`,

  // Marketplace
  BASE_CRUD_SUPPLIES_REVIEW: `${baseUrl}/marketplace/reviews`,
  REPORT_SUPPLIES_REVIEW: `${baseUrl}/marketplace/report-review`,

  // RFQ
  BASE_CRUD_RFQ: `${baseUrl}/material-requests`,
  GET_MATERIAL_REQUESTS: `${baseUrl}/material-requests`,
  GET_FOLLOWED_RFQS: `${baseUrl}/material-requests/followed-quote-requests`,
  CREATE_FOLLOW_RFQ: `${baseUrl}/material-requests/followed-quote-requests?id=`,
  DELETE_FOLLOW_RFQ: `${baseUrl}/material-requests/followed-quote-requests?id=`,
  GET_USER_RFQ: `${baseUrl}/material-requests/rfqs/`,
  GET_SUB_CATEGORIES: `${baseUrl}/lots/data/subcategory`,
  DELETE_RFQ: `${baseUrl}/material-requests`,
  LIST_RFQ_3RD_PARTY_APPROVAL_REQUESTS: `${baseUrl}/material-requests/rfqs/:rfqId/approval-requests`,
  DETAIL_RFQ_3RD_PARTY_APPROVAL_REQUEST: `${baseUrl}/material-requests/rfqs/:rfqId/approval-requests/:approvalRequestId`,

  GET_USER_RFQS: `${baseUrl}/material-requests/rfqs/`,
  GET_USER_SELLING_RFQS: `${baseUrl}/material-requests/selling`,
  UPDATE_SELLING_BOARD_CONFIG: `${baseUrl}/material-requests/quote-requests/board-config`,
  GET_RFQ_HIDDEN_DATA: `${baseUrl}/material-requests/hidden-data?id=`,

  // BID
  UPDATE_SOURCING: `${baseUrl}/products-supplied/matches/material-request/`,

  // Notes
  BASE_CRUD_NOTE: `${baseUrl}/material-requests/quote-requests/:id/notes`,

  // Auctions
  LISTING_AUCTIONS: `${baseUrl}/auctions/listing-auctions`,
  LISTINGS_AUCTIONS_BY_ID: `${baseUrl}/auctions/listing-auctions/:id`,
  BASE_AUCTION_OFFER: `${baseUrl}/material-requests/auctions`,
  GET_AUCTION_BID_BY_ID: `${baseUrl}/auctions/quote-auctions/:auctionID/bids/:bidID`,
  GET_AUCTION_BY_ID: `${baseUrl}/auctions/quote-auctions/:auctionID`,
  CREATE_QUOTE_AUCTION: `${baseUrl}/auctions/quote-auctions`,
  CREATE_QUOTE_AUCTIONS_V2: `${baseUrl}/auctions/v2/quote-auctions`,
  POST_BID_FOR_QUOTE_AUCTION: `${baseUrl}/auctions/quote-auctions/:auctionID/bids`,
  UPDATE_QUOTE_BID: `${baseUrl}/auctions/quote-auctions/:auctionID/bids/:bidID`,
  CREATE_AUCTION_BID: `${baseUrl}/auctions/bids`,
  GET_AUCTION_DEADLINE: `${baseUrl}/auctions/auction-deadline`,
  GET_RFQ_AUCTIONS: `${baseUrl}/auctions/rfqs/auctions`,
  GET_RFQ_AUCTIONS_INSIGHTS: `${baseUrl}/auctions/insights/rfqs/:rfqId`,

  // Notifications
  USER_NOTIFICATIONS: `${baseUrl}/notifications`,

  // Listings
  GET_LISTING: `${baseUrl}/listings`,
  GET_LISTING_PUBLIC: `${baseUrl}/listings/public`,

  // Company
  PUBLISH_COMPANY: `${baseUrl}/company`,
  GET_COMPANIES: `${baseUrl}/company/companies`,
  GET_ERV_COMPANIES: `${baseUrl}/company/erv/companies`,
  BASE_CRUD_COMPANY: `${baseUrl}/company`,

  // tmsCompany
  GET_TMS_COMPANIES: `${baseUrl}/tms-companies/search/:searchValue`,

  ENUM_BY_NAME: `${baseUrl}/lots/data/enumForm?enumName=`,
  // Checkout
  GET_S3_UPLOAD_URL: `${baseUrl}/utils/s3-uploader`,

  UPDATE_PRISMA_USER: `${baseUrl}/users`,
  GET_USER_NOTIFICATIONS: `${baseUrl}/notifications/all`,

  GET_COMPANY_ORDERS: `${baseUrl}/company/:companyId/orders`,
  GET_COMPANY_INVOICES: `${baseUrl}/netsuite/invoices/company/:companyId`,
  NETSUITE_DOWNLOAD_COMPANY_MTR: `${baseUrl}/netsuite/files`,
  GET_COMPANY_INVOICES_ITEMS: `${baseUrl}/netsuite/invoice/items/company/:companyId`,
  GET_COMPANY_MEMBERS: `${baseUrl}/company/:companyId/members?orderBy=createdAt_DESC`,
  GET_ERV_COMPANY_MEMBERS: `${baseUrl}/company/erv/companies/:companyId/members?orderBy=createdAt_DESC`,
  GET_COMPANY_MEMBERS_EMAILS: `${baseUrl}/company/:companyId/get-emails`,
  GET_USER: `${baseUrl}/users/:memberId`,
  RESEND_SET_PASSWORD_EMAIL: `${baseUrl}/users/resend-reset-password-email`,

  GET_REGIONS: `${baseUrl}/utils/regions`,
  GET_ROLES: `${baseUrl}/admin/roles`,

  BASE_CRUD_MEMBER_ROLE: `${baseUrl}/users/:memberId/role`,
  BASE_CRUD_MEMBER_ROLES: `${baseUrl}/users/:memberId/roles`,

  // User segments
  GET_LISTINGS_FILTERS_BY_SEGMENT: `${baseUrl}/listings/segment/`,

  // System Roles
  GET_USERS_BY_ROLE: `${baseUrl}/users/role/:role`,
  // Overview Cards
  GET_LISTINGS_SUMMARY_CARD: `${baseUrl}/users/dashboard/card/listings-summary`,
  GET_RFQS_SUMMARY_CARD: `${baseUrl}/users/dashboard/card/rfqs-summary`,
  GET_LISTINGS_BREAKDOWN_CARD: `${baseUrl}/users/dashboard/card/listings`,
  GET_RFQS_SUBMITTED_CARD: `${baseUrl}/users/dashboard/card/rfqs`,
  GET_MY_PRODUCT_LINES_CARD: `${baseUrl}/users/dashboard/card/product-lines`,
  GET_PRODUCT_LINE_MATCHES_CARD: `${baseUrl}/users/dashboard/card/product-line-matches`,
  GET_LISTING_BIDS_CARD: `${baseUrl}/users/dashboard/card/listing-bids`,
  GET_MATERIAL_REQUESTS_BIDS_CARD: `${baseUrl}/users/dashboard/card/material-requests-bids`,

  // Matching
  GET_MATCHES: `${baseUrl}/matching/resources`,
  GET_LISTINGS_MATCHES_FROM_CRITERIA: `${baseUrl}/matching/search-state`,
  GET_RESOURCE_MATCHES_BY_QUERY: `${baseUrl}/matching/matches/query/:resourceId`,

  // Insights
  GET_NEWS_ARTICLES: `${baseUrl}/insights/news/articles`,
  GET_INSIGHTS_BY_TYPE: `${baseUrl}/insights/type`,
  GET_INSIGHTS_BY_TYPE_PUBLIC: `${baseUrl}/insights/public/type`,
  GET_INSIGHTS_LATEST: `${baseUrl}/insights/items/latest`,

  // Carbon
  POST_CARBON_PROJECT_PURCHASE: `${baseUrl}/carbon/project/:projectId/purchase`,
};

export default urls;
