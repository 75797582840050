import * as Sentry from '@sentry/react';
import axios from 'axios';

import { getApiRoot } from 'utils/apiUtils';
import { getIdentityToken } from 'utils/auth';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(async request => {
  try {
    if (request.url?.startsWith(getApiRoot() ?? '')) {
      const identityToken = await getIdentityToken();
      request.headers.Authorization = identityToken ?? '';
      request.headers['x-deployment-region'] = process.env.REGION ?? 'NA';
    }

    const existingTransaction = Sentry.getCurrentHub().getScope()?.getTransaction();

    // Create a transaction if one does not exist in order to work around
    // https://github.com/getsentry/sentry-javascript/issues/3169
    // https://github.com/getsentry/sentry-javascript/issues/4072
    const transaction =
      existingTransaction ??
      Sentry.startTransaction({ name: `API Request: ${request.method} ${request.url}` });

    Sentry.getCurrentHub().configureScope(scope => scope.setSpan(transaction));

    (request as any).tracing = {
      transaction,
    };

    return request;
  } catch (err) {
    console.error(err);
    Sentry.captureException(err);
    return request;
  }
});

axiosInstance.interceptors.response.use(
  response => {
    (response.config as any).tracing?.transaction?.finish();
    return response;
  },
  error => {
    (error.config as any).tracing?.transaction?.finish();
    return Promise.reject(error);
  }
);

export default axiosInstance;
