import { useEffect, useState } from 'react';

import * as Sentry from '@sentry/react';
import { useLDClient, useFlags as useLdFlags } from 'launchdarkly-react-client-sdk';

import { useAuthContext } from 'context/hooks';

const useFlags = (): Record<string, boolean | string> => {
  // LD Hook object for just flags
  const client = useLDClient();
  const flagsObject = useLdFlags();
  const { email } = client?.getContext() || {};

  return { ...flagsObject, hasLdUser: !!email };
};

const useUserFlags = (): Record<string, boolean | string | null> => {
  // LD Hook to tie into user based flags
  const ldClient = useLDClient();
  const flags = useFlags();
  const [isLdReady, setReady] = useState<boolean | null>(null);
  const { email, isReibus, companyName, firstName, lastName } = useAuthContext();

  useEffect(() => {
    if (!ldClient) {
      return;
    }

    const ldContext = email
      ? {
          key: email,
          email,
          firstName,
          lastName,
          secondary: companyName,
          custom: {
            isReibus,
          },
        }
      : {
          key: 'anonymous',
        };

    ldClient.identify(ldContext).then(async () => {
      try {
        await ldClient.waitForInitialization();
        setReady(true);
      } catch (err) {
        console.error(err);
        Sentry.captureException(err);
        setReady(false);
      }
    });
  }, [email, ldClient]);

  return { ...flags, isLdReady };
};

const withLDHoc = (Component: any) => (props: any) => {
  // LD HOC to attach LD to class based react components
  const flags = useUserFlags();
  return <Component {...props} flags={flags} />;
};

// RM-7657 and RM-8366 - This hook is intended to combine checks for
// EU and the values of the finance/logistics flags to determine
// if certain features should be rendered in the EU site
type Product = 'finance' | 'logistics';

const flagsForProduct: Record<Product, string> = {
  finance: 'rm8366ShowFinanceOnEu',
  logistics: 'rm8366ShowLogisticsOnEu',
};

const useFeatureDeploymentRegionResolver = (product: Product) => {
  const { [product]: flag = '' } = flagsForProduct;

  const { [flag]: showProduct = false } = useLdFlags();

  return process.env.REGION === 'NA' || showProduct;
};

export { useFlags, useUserFlags, withLDHoc, useFeatureDeploymentRegionResolver };
