import * as Sentry from '@sentry/react';

import UserService from '../../services/UserService';
import { updateUserAttributes } from '../auth';

const userService = new UserService();

class MembersService {
  getMembers = companyId => {
    return userService.getCompanyMembers(companyId, 'createdAt_DESC').then(({ data }) => data);
  };

  getMembersEmails = companyId => {
    return userService.getCompanyMembersEmails(companyId).then(({ data }) => data);
  };

  getMemberDetail = memberId => {
    return userService
      .getMemberDetail(memberId)
      .then(({ data }) => data?.user)
      .catch(error => {
        throw error?.errors;
      });
  };

  updateAwsMember = async attributeList => {
    return updateUserAttributes(attributeList);
  };

  updatePrismaMember = async data => {
    const userId = data.userId ? `${data.userId}` : '';
    const userInfo = {
      userId,
      firstName: data.firstName,
      lastName: data.lastName,
      address1: data.address,
      industry: data.industry,
      phoneNumber: data.phoneNumber,
      companyName: data.companyName,
      jobTitle: data.jobTitle,
      updateType: 'personalInfoUpdate',
      email: data.email,
      mailingAddress: data.mailingAddress,
      directReports: data.directReports,
      manager: data.manager,
      permission: data.permission,
      shippingLocation: data.shippingLocation,
      shippingLocationPostalCode: data.shippingLocationPostalCode,
      measurementSystem: data.measurementSystem,
      preferredCurrency: data.preferredCurrency,
    };

    const { data: resolvedData } = await userService.updatePrismaMember(userId, userInfo);
    return resolvedData;
  };

  submitAccountSettingsToPrisma = async data => {
    const accountData = {
      ...data,
      updateType: 'accountSettingsUpdate',
    };

    return await userService
      .updateAccountSettings(accountData)
      .then(({ data }) => data)
      .catch(error => {
        console.error(error);
        throw error;
      });
  };

  getMemberRoles = async userId => {
    return userService.getMemberRoles(userId).then(({ data }) => data.userRoles);
  };

  getRolesAndRegions = async () => {
    let regionsResp;
    try {
      const { data } = await userService.getRegions();
      regionsResp = data;
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }

    let rolesResp;
    try {
      const { data } = await userService.getRoles();
      rolesResp = data;
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }

    const roles = rolesResp.roles.map((role, index) => ({
      id: role.id || index,
      value: role.value || role,
    }));

    return { regions: regionsResp.regions, roles };
  };

  updateMemberRoleInfo = async (memberInfo, roleInfo) => {
    const currentRoles = memberInfo.userRole.map(role => role.id);

    const newRoles = roleInfo.map(role => role.id);

    const allRoles = [...new Set([...currentRoles, ...newRoles])];

    return await Promise.all(
      allRoles.map(roleId => {
        const newRoleInfo = newRoles.find(id => id === roleId);
        const itCurrentExists = currentRoles.find(id => id === roleId);

        const apiMethod = itCurrentExists
          ? !newRoleInfo
            ? 'deleteMemberRole'
            : 'updateMemberRole'
          : 'addMemberRole';

        return userService[apiMethod](memberInfo.id, roleId, newRoleInfo);
      })
    );
  };

  removeMember = async (memberToDelete, memberForTranfer) => {
    const memberInfo = {
      receivingUser: memberForTranfer,
      deletingUser: memberToDelete,
    };

    return await userService
      .deletePrismaMember(memberInfo)
      .then(({ data }) => data)
      .catch(() => {
        Error('There was a problem deleting this user. Try again.');
      });
  };

  getCurrentUser = async () => {
    return userService
      .getPrismaUsers()
      .then(({ data }) => data)
      .catch(error => {
        throw error;
      });
  };
}

const membersService = new MembersService();
export default membersService;
