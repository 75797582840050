import { createContext, useState, useEffect, PropsWithChildren } from 'react';

import {
  useCurrentUser,
  useAuthorizationToken,
  useUserSession,
  useLogout,
} from '@reibus/frontend-utility';
import * as Sentry from '@sentry/react';
import { ICognitoUserAttributeData } from 'amazon-cognito-identity-js';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useQuery } from 'react-query';

import useForceLogin from 'hooks/useForceLogin';
import { AuthenticationError, getUserAttributesAsync } from 'utils/auth';
import { mixpanel } from 'utils/mixpanel';

import {
  AuthUserType,
  AuthUserDataType,
  AuthContextMethods,
  AuthContext as AuthContextType,
} from './types';

import type { Currency } from '@reibus/reibus-core-utils/dist/src/objects/currency/priceSymbols';

const userInitState: AuthUserType = {
  isReibus: false,
  identityToken: '',
  cognitoGroups: [],
  cognitoUser: {},
};

const userDataInitState: AuthUserDataType = {
  // App State
  isVerified: false,
  // User State
  id: '',
  email: '',
  name: '',
  firstName: '',
  lastName: '',
  companyName: '',
  company: {},
  salesforceID: null,
  createdAt: '',
  firstLoginTime: '',
  firstLoginTimeUTC: '',
  jobTitle: undefined,
  shippingLocation: 'USA',
  shippingLocationPostalCode: '',
  isImperial: process.env.REGION == 'NA',
  measurementSystem: process.env.REGION == 'NA' ? 'IMPERIAL' : 'METRIC',
  preferredLocale: 'en-US',
  preferredCurrency: 'USD' as Currency,
  userId: '',
  userRoles: [],
  username: '',
  mailingAddress: undefined,
  manager: undefined,
  directReports: [],
  phoneNumber: '',
  permission: {},
  isCsmAdmin: false,
  insightsPreferences: null,
  promptedForNotifications: true,
  isInsightsExperienceComplete: false,
  registrationState: 'Active',
  productInterests: null,
  includeFreightInUnitPrice: false,

  // Notifications
  notificationView: 'ALL',
  articlesFrequency: 'IMMEDIATELY',
  recieveBidsOnMaterial: false,
  articlesNotifications: false,
  insightsFrequency: 'IMMEDIATELY',
  insightsNotifications: false,
  insightsMobileNotifications: false,
  productLinesNotifications: false,
  rfqNewListingsMatchingNotifications: false,
  rfqNewListingsMatchingMobileNotifications: false,
  logisticsNotifications: null,
  UserNotificationPreferences: [],
  UserPrompts: [],
};

const AuthContextSetters = {
  updateUserData: () => {},
  logout: async () => {},
} as AuthContextMethods;

export const AuthContext = createContext({
  ...userInitState,
  ...userDataInitState,
  ...AuthContextSetters,
});

export const AuthProvider = ({ children }: PropsWithChildren<{}>) => {
  const [activeUser, setActiveUser] = useState(userInitState);
  const [activeUserData, setActiveUserData] = useState(userDataInitState);
  const { cognitoUser } = activeUser;
  const { firstName, lastName, company, email } = activeUserData;
  const ldClient = useLDClient();

  const { data: currentUser, isFetching: isRetrievingUser } = useCurrentUser();
  const { data: userSession, isFetching: isRetrievingUserSession, failureCount } = useUserSession();
  const authToken = useAuthorizationToken();

  const { data: attributes = null } = useQuery<ICognitoUserAttributeData[] | null>(
    ['userAttributes', { authToken }],
    async () => {
      if (!authToken) {
        return null;
      }

      return await getUserAttributesAsync();
    },
    {
      onError: error => {
        console.error(error);

        if (!(error instanceof AuthenticationError)) {
          Sentry.captureException(error);
        }
      },
      refetchInterval: 80000,
    }
  );

  const isResettingPassword =
    attributes?.find(({ Name }) => Name === 'custom:reset_password')?.Value === 'true';

  const isLoggedIn = !!currentUser && !isResettingPassword;
  const isLoggingIn = isRetrievingUser || (isRetrievingUserSession && failureCount < 2);

  const { mutateAsync: logoutUser } = useLogout();
  const forceLogin = useForceLogin({ redirectOnLogin: false });

  const logout = async () => {
    forceLogin();
    await logoutUser();
    setActiveUser(userInitState);
    setActiveUserData(userDataInitState);
  };

  useEffect(() => {
    if (!userSession) {
      return;
    }

    setActiveUser(currentActiveUser => ({
      ...currentActiveUser,
      ...userSession,
      isReibus: userSession.isReibusUser,
    }));
  }, [userSession]);

  useEffect(() => {
    try {
      if (email && isLoggedIn) {
        mixpanel.identify(cognitoUser?.username);
        mixpanel.register({ email });
        mixpanel.people.set({
          $email: email,
          $first_name: firstName,
          $last_name: lastName,
          Company: company?.name,
        });
        Sentry.setUser({ email, username: cognitoUser?.username });
      }
      if (ldClient && email) {
        // track LD user by default.
        ldClient.identify({
          key: email,
          email,
          firstName,
          lastName,
          secondary: company.name,
          custom: {
            isReibus: activeUser.isReibus,
          },
        });
      }

      if (!isLoggedIn) {
        Sentry.configureScope(scope => scope.setUser(null));
      }
    } catch (error) {
      // eat this error, more than likely failing cause mixpanel not configured
      console.error(error);
      Sentry.captureException(error);
    }
  }, [email, isLoggedIn]);

  useEffect(() => {
    if (!authToken) {
      setActiveUser({ ...userInitState });
      setActiveUserData({ ...userDataInitState });
    }
  }, [currentUser, authToken]);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    const {
      id: userId,
      permission,
      shippingLocation,
      measurementSystem,
      userRole: userRoles = [],
      company,
    } = currentUser;

    const translatedUserState = {
      ...currentUser,
      userId,
      company: company ?? { id: 1, name: '' },
      userRoles,
      companyName: company?.name,
      isImperial: measurementSystem === 'IMPERIAL',
      name: `${currentUser.firstName} ${currentUser.lastName}`,
      isCsmAdmin: permission?.systemRoles?.some(role =>
        ['ReibusSuperAdmin', 'ReibusCompanyAdmin'].includes(role)
      ),
      email: attributes?.find(el => el.Name === 'email')?.Value || currentUser.email,
    };

    setActiveUserData(translatedUserState);

    localStorage.setItem('shippingLocation', shippingLocation);
  }, [currentUser]);

  const AuthContextData: AuthContextType = {
    ...activeUser,
    ...activeUserData,
    identityToken: authToken,
    currentUser,
    attributes,
    isLoggingIn,
    isLoggedIn,
    logout,
    updateUserData: userPreferences => {
      // TODO need to make this a mutation to handle things in
      // src/components/dashboard/account/MyProfileSection.tsx
      setActiveUserData(currentActiveUserData => ({
        ...currentActiveUserData,
        ...userPreferences,
      }));
    },
  };

  return <AuthContext.Provider value={AuthContextData}>{children}</AuthContext.Provider>;
};
