import * as React from 'react';
import { StrictMode } from 'react';

// eslint-disable-next-line import/order
import '../init';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { Router, Location, Redirect } from '@reach/router';
import { queryClient, ldClient } from '@reibus/frontend-utility';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { Helmet } from 'react-helmet';
import { I18nextProvider } from 'react-i18next';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import BUILD from 'build.json';
import MarketingSiteLink from 'components/MarketingSiteLink';
import appTheme from 'config/theme/App';
import { AuthProvider } from 'context/auth.context';
import { CompanyContextProvider } from 'context/company.context';
import { DashProvider } from 'context/dash.context';
import { MemberContextProvider } from 'context/members.context';
import AppRoot from 'pages';
import { i18nSSR as i18n } from 'utils/i18n';
import 'utils/mixpanel';

const RenderGDPR = () => {
  const hasGDPR = window.localStorage.getItem('hasGDPR') === 'true';
  if (hasGDPR) {
    return null;
  }

  return (
    <div className="gdpr">
      <span>
        We use cookies to provide our services. By using this website, you agree to this.{' '}
        <MarketingSiteLink to="/privacy-policy">More Information</MarketingSiteLink>
      </span>
      <button
        className="button"
        onClick={ev => {
          window.localStorage.setItem('hasGDPR', 'true');
          ev.target.parentElement.classList.add('gdpr--close');
        }}
      >
        Ok
      </button>
    </div>
  );
};

const LocationWrappedRoot = ({ location, navigate }) => {
  const windowHref = window.location.href;
  React.useEffect(() => {
    if (windowHref !== location.href) {
      navigate(windowHref, { replace: true });
    }
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return location.href !== windowHref ? null : (
    <StrictMode>
      <Helmet>
        <link
          crossOrigin="true"
          href={`https://${process.env.ALGOLIA_APP_ID}-1.algolianet.com`}
          rel="preconnect"
        />
        <link
          crossOrigin="true"
          href={`https://${process.env.ALGOLIA_APP_ID}-2.algolianet.com`}
          rel="preconnect"
        />
        <link
          crossOrigin="true"
          href={`https://${process.env.ALGOLIA_APP_ID}-3.algolianet.com`}
          rel="preconnect"
        />
        <meta name="marketplace-ui-version" content={BUILD.VERSION} />
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Helmet>
      <LDProvider clientSideID={process.env.LAUNCH_DARKLY_CLIENT_ID ?? ''} ldClient={ldClient}>
        <I18nextProvider i18n={i18n}>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <MemberContextProvider>
                <DashProvider>
                  <CompanyContextProvider>
                    <StyledEngineProvider injectFirst>
                      <ThemeProvider theme={appTheme}>
                        <CssBaseline />
                        <Router location={location}>
                          <Redirect from="/financial/*" to="/" noThrow />
                          <AppRoot default />
                        </Router>
                        <RenderGDPR />
                      </ThemeProvider>
                    </StyledEngineProvider>
                  </CompanyContextProvider>
                </DashProvider>
              </MemberContextProvider>
            </AuthProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </I18nextProvider>
      </LDProvider>
    </StrictMode>
  );
};

const MarketplaceRoot = () => {
  return (
    <Location>
      {({ location, navigate }) => <LocationWrappedRoot location={location} navigate={navigate} />}
    </Location>
  );
};

export default MarketplaceRoot;
