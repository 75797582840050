/* eslint-disable no-console */
import { unstable_ClassNameGenerator as ClassNameGenerator } from '@mui/material';

import * as registerServiceWorker from './registerServiceWorker';

// We want this whether developing locally or not because
// it will affect how classes are applied
ClassNameGenerator.configure(componentName =>
  componentName.startsWith('Mui') ? componentName : `rmf-${componentName}`
);

registerServiceWorker.register({
  // This is run when a new service worker has been installed and there is
  // no other active service worker (i.e. we are installing the service worker
  // for the first time)
  onSuccess: async registration => {
    // We want to run this code only if we detect a new service worker is active.
    // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
    if (!registration?.active) {
      console.warn('Service worker is not active in onSuccess. Skipping...');
      return;
    }

    console.debug(`Service worker (${registration.active.scriptURL}) is active.`);
  },
  // This is run when a new service worker has been installed and there is
  // an active service worker (i.e. we are updating the service worker)
  onUpdate: async registration => {
    // We want to run this code only if we detect a new service worker is
    // waiting to be activated.
    // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
    if (!registration?.waiting) {
      console.debug('Service worker is not waiting. Done.');
      return;
    }

    // We are going to reload here and swap to the new service worker
    // but we could also do something else, like show a notification to
    // the user that a new version is available and let them decide to
    // reload or not.
    console.debug('New service worker is waiting to be activated. Reloading...');
    window.location.reload();
    registration.waiting.postMessage({ type: 'SKIP_WAITING' });
  },
});
