import * as React from 'react';

import { AuthContext } from './auth.context';
import { DashContext } from './dash.context';
import {
  useMemberState as useUntypedMemberState,
  useMemberDispatch,
  getMemberList,
  getselectedCompanyMembers,
  updateCurrentUser,
  removePasswordValidation,
  updateMember,
  deleteMember,
  getMemberDetail,
  getMemberEmails,
  changeUserPassword,
  changeUserNotificationsSettings,
  getRolesAndRegions,
  updateMemberRole,
  getCurrentUser,
  ActionType,
} from './members.context';

import type {
  MemberContext,
  AuthContext as AuthContextType,
  DashContext as DashContextTypes,
} from './types';

export function useAuthContext(): AuthContextType {
  return React.useContext(AuthContext);
}

export function useMemberState(): MemberContext {
  return useUntypedMemberState();
}

export function useDashContext(): DashContextTypes {
  return React.useContext(DashContext);
}

export function useMemberActions() {
  const dispatch = useMemberDispatch();
  return React.useMemo(
    () => ({
      getMemberList: getMemberList.bind(null, dispatch),
      getselectedCompanyMembers: getselectedCompanyMembers.bind(null, dispatch),
      updateCurrentUser: updateCurrentUser.bind(null, dispatch),
      removePasswordValidation: removePasswordValidation.bind(null, dispatch),
      updateMember: updateMember.bind(null, dispatch),
      deleteMember: deleteMember.bind(null, dispatch),
      getMemberDetail: getMemberDetail.bind(null, dispatch),
      getMemberEmails: getMemberEmails.bind(null, dispatch),
      changeUserPassword: changeUserPassword.bind(null, dispatch),
      changeUserNotificationsSettings: changeUserNotificationsSettings.bind(null, dispatch),
      getRolesAndRegions: getRolesAndRegions.bind(null, dispatch),
      updateMemberRole: updateMemberRole.bind(null, dispatch),
      getCurrentUser: getCurrentUser.bind(null, dispatch),
      finishFlow: () => {
        dispatch({ type: ActionType.FINISH_FLOW });
      },
    }),
    [dispatch]
  );
}
