import { convertLengthToFT } from 'utils/convertLengthToFT';

// Note: This is not a comprehensive list. Continue to update with proper formats
const longPipeProduct = ['PIPE', 'TUBE'] as const;
type LongPipeProduct = (typeof longPipeProduct)[number];
const isLongPipeProduct = (product: any): product is LongPipeProduct =>
  longPipeProduct.includes(product);

export const calculateTotalPriceBasedOnType = data => {
  const { format } = data;
  const lotPrice = data.pricePerLb ?? data.price ?? 0;
  const lotQuantity = data.weight ?? data.quantitySelected ?? 0;

  const finalPrice = isLongPipeProduct(format)
    ? data.pricePerLinealFoot * convertLengthToFT(data.totalLength)
    : lotPrice * lotQuantity;

  return Number(finalPrice);
};

export const getTotalPriceForLotArray = (selectedLots = []) =>
  selectedLots.reduce((total, obj) => {
    const additionalTotal = calculateTotalPriceBasedOnType(obj);
    return total + additionalTotal;
  }, 0);

export const calculateTotalDisplayPriceBasedOnType = data => {
  return calculateTotalPriceBasedOnType(data).toFixed(2);
};
