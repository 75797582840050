import { promisify } from 'util';

import { navigate } from '@reach/router';
import * as Sentry from '@sentry/react';
import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserAttribute,
  AuthenticationDetails,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import * as AWS from 'aws-sdk/global';
import jwtDecode from 'jwt-decode';

import appConfig from '../config/aws-config';

import { isPublicRoute } from './privatedUrlList';

import type {
  IAuthenticationDetailsData,
  ICognitoUserData,
  ICognitoUserAttributeData,
} from 'amazon-cognito-identity-js';
import type { JWTPayload } from 'jwt-decode';

export const userPool = () =>
  new CognitoUserPool({
    UserPoolId: appConfig.UserPoolId,
    ClientId: appConfig.ClientId,
  });

export interface AuthError {
  message?: string;
  name?: string;
  code?: string;
}

export interface UserAuthInformation {
  cognitoUser: CognitoUser;
  isReibusUser: boolean;
  identityToken: string;
  cognitoGroups: string[];
}

export const updateUserAttributes = (attributesArray: ICognitoUserAttributeData[]) =>
  new Promise((resolve, reject) => {
    // get the current Cognito user
    const cognitoUser = userPool().getCurrentUser();
    if (cognitoUser === null) {
      reject(Error('no cognito user'));
      return;
    }

    // NOTE: getSession must be called to authenticate user before calling updateUserAttributes
    // the types of this callback are not documented
    cognitoUser.getSession((error, _: CognitoUserSession) => {
      if (error) {
        console.error('error getting user sesion', error);
        return reject(error);
      }

      // update the user attributes
      cognitoUser.updateAttributes(attributesArray, (error: Error, result: string) => {
        if (error) {
          reject(error);
          return;
        }
        resolve(result);
      });
    });
  });

export class AuthenticationError extends Error {
  type: string;

  constructor(type: string, message: string) {
    super(message);
    this.type = type;
  }
}

export const updatePassword = (oldPassword: string, newPassword: string) =>
  new Promise<void>((resolve, reject) => {
    const cognitoUser = userPool().getCurrentUser();

    if (cognitoUser == null) {
      console.error('cognito user is null');
      return reject(new AuthenticationError('Unauthenticated', 'cognito user is null'));
    }

    // NOTE: getSession must be called to authenticate user before calling updatePassword
    // the types of this callback are not documented
    cognitoUser.getSession((error, session: CognitoUserSession) => {
      if (error) {
        console.error('error getting user session', error);
        reject(new AuthenticationError('GetSession', error.message));
        return;
      }

      // generate the array
      cognitoUser.changePassword(oldPassword, newPassword, (error: Error, _) => {
        if (error) {
          console.error('error with changePassword', error);
          reject(new AuthenticationError('ChangePassword', error.message));
          return;
        }

        resolve();
      });
    });
  });

/**
 * Attempts to resolve the UserAuthInformation for the existing session
 *
 * @returns promise that resolves to user information if user session exists.
 * If session doesn't exist, resolves to null if current route is public. Otherwise is rejected.
 */
export const checkUserSession = () =>
  new Promise<UserAuthInformation | null>((resolve, reject) => {
    const cognitoUser = userPool()?.getCurrentUser() ?? null;
    if (cognitoUser == null) {
      if (isPublicRoute()) {
        resolve(null);
      } else {
        reject(new AuthenticationError('Unauthenticated', 'No cognito user'));
      }
      return;
    }

    // the types of this callback are not documented
    cognitoUser.getSession((error, session: CognitoUserSession) => {
      if (error || !session) {
        reject(error ?? new AuthenticationError('Unauthenticated', 'No session'));
        return;
      }

      const identityToken = session.getIdToken().getJwtToken();

      const awsUserInformation: UserAuthInformation = {
        cognitoUser,
        isReibusUser: false,
        identityToken,
        cognitoGroups: [],
      };

      try {
        const decodedClaims = jwtDecode<JWTPayload>(session?.getAccessToken()?.getJwtToken());

        if (decodedClaims?.[`cognito:groups`]) {
          awsUserInformation.cognitoGroups = decodedClaims[`cognito:groups`];
          if (decodedClaims[`cognito:groups`].indexOf('reibus') !== -1) {
            awsUserInformation.isReibusUser = true;
          }
        }

        resolve(awsUserInformation);
      } catch (e: jwtDecode.InvalidTokenError) {
        console.error('Error parsing JWT', e);
        return awsUserInformation;
      }
    });
  });

export const getUser = (): CognitoUser | null => userPool().getCurrentUser();

export const deleteUser = (cognitoUser: CognitoUser) =>
  new Promise<string>((resolve, reject) => {
    cognitoUser.deleteUser((error: Error, result: string) => {
      if (error) {
        reject(error);
        return;
      }
      resolve(result);
    });
  });

export const getUserAttributesAsync = async (): Promise<ICognitoUserAttributeData[]> => {
  return await new Promise((resolve, reject) => {
    getUserAttributes(({ attributes, error }) => {
      if (error) {
        reject(error);
      } else {
        resolve(attributes ?? []);
      }
    });
  });
};

export const getUserAttributes = (
  callback: (response: { attributes?: ICognitoUserAttributeData[]; error?: AuthError }) => void
) => {
  const cognitoUser = userPool().getCurrentUser();

  if (cognitoUser != null) {
    cognitoUser.getSession(error => {
      if (error) {
        console.error(error.message || JSON.stringify(error));
        callback({ error });
        return;
      }

      // NOTE: getSession must be called to authenticate user before calling getUserAttributes
      cognitoUser.getUserAttributes((error?: Error, attributes?: CognitoUserAttribute[]) => {
        if (error) {
          // Handle error
          console.error(error);
          console.error(error.message || JSON.stringify(error));
          callback({ error });
          return;
        }

        // Do something with attributes
        callback({
          attributes: attributes?.map(attribute => attribute.toJSON() as ICognitoUserAttributeData),
        });
      });
    });
  }
};

/**
 * since credential id gets cached, clear cached id and re-initialize it on every login
 * issue: https://github.com/aws/aws-sdk-js/issues/609
 * @param credentialParams
 * @returns [AWS.Credentials] that are not from cache
 */
const freshCredentials = (
  credentialParams: AWS.CognitoIdentityCredentials.CognitoIdentityOptions
): AWS.Credentials => {
  new AWS.CognitoIdentityCredentials(credentialParams).clearCachedId();
  return new AWS.CognitoIdentityCredentials(credentialParams);
};

export const login = promisify(
  (
    loginRequest: {
      email: string;
      password: string;
      newPassword?: string;
    },
    callback: (
      error: AuthError | null,
      response: {
        userAuthInfo?: UserAuthInformation;
        userAttributes?: ICognitoUserAttributeData[];
      } | null
    ) => void
  ) => {
    const { email, password, newPassword } = loginRequest;
    const authenticationData: IAuthenticationDetailsData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    // username and email are the same
    const userData: ICognitoUserData = {
      Username: email,
      Pool: userPool(),
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (session: CognitoUserSession, userConfirmationNecessary?: boolean) => {
        // might not need below
        const accessToken = session?.getAccessToken()?.getJwtToken();
        const identityToken = session?.getIdToken()?.getJwtToken();
        if (accessToken === undefined || identityToken === undefined) {
          callback(
            {
              name: 'TokenError',
              message: 'Access token or identity token is undefined',
            },
            null
          );
          return;
        }
        const payload = jwtDecode(accessToken);

        const isReibusUser =
          payload[`cognito:groups`] && payload[`cognito:groups`].indexOf('reibus') !== -1;

        const cognitoGroups = payload[`cognito:groups`] ?? [];

        const credentialParams = {
          IdentityPoolId: appConfig.IdentityPoolId, // your identity pool id here
          Logins: {
            // Change the key below according to the specific region your user pool is in.
            [`cognito-idp.${appConfig.region}.amazonaws.com/${appConfig.UserPoolId}`]:
              identityToken,
          },
        };

        AWS.config.region = 'us-east-1';
        AWS.config.credentials = freshCredentials(credentialParams);

        // refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
        (AWS.config.credentials as AWS.Credentials).refresh(error => {
          if (error) {
            console.error(error);
            callback(error, null);
            return;
          }

          cognitoUser.getSession((error, _) => {
            if (error) {
              console.error('Error getting user Session');
              callback(error, null);
              return;
            }

            cognitoUser.getUserAttributes((error, userAttributes) => {
              if (error) {
                console.error('Error getting user Attributes');
                callback(error, null);
                return;
              }
              callback(null, {
                userAuthInfo: { cognitoUser, isReibusUser, identityToken, cognitoGroups },
                userAttributes: userAttributes?.map(
                  attribute => attribute.toJSON() as ICognitoUserAttributeData
                ),
              });
            });
          });
        });
      },
      onFailure: error => {
        if (error.name === 'UserNotConfirmedException') {
          // resend Verification
          resendVerification(email);
          navigate('/verification/', {
            state: {
              username: email,
              password,
            },
          });
        } else {
          callback(error, null);
        }
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        if (!newPassword) {
          callback(
            {
              name: 'NewPassword',
              message: 'Please enter a new password to confirm your account',
            },
            null
          );
          return;
        }

        delete userAttributes.email_verified;
        delete userAttributes.phone_number_verified;
        confirmNewPassword(cognitoUser, userAttributes, newPassword, error => {
          if (error) {
            callback(error, null);
          } else {
            callback(null, { userAttributes });
          }
        });
      },
    });
  }
);

export const getIdentityToken = async (): Promise<string | null> => {
  const cognitoUser = userPool()?.getCurrentUser() ?? null;

  if (!cognitoUser) {
    return null;
  }

  try {
    const session = (await promisify(
      cognitoUser.getSession.bind(cognitoUser)
    )()) as CognitoUserSession;
    return session?.getIdToken()?.getJwtToken() ?? null;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    return null;
  }
};

export const confirmNewPassword = (
  cognitoUser: CognitoUser,
  userAttributes: CognitoUserAttribute[],
  newPassword: string,
  callback: (error?: AuthError) => void
) => {
  cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
    onSuccess: (session: CognitoUserSession) => {
      // might not need below
      // const accessToken = result.getAccessToken().getJwtToken();
      const credentialParams = {
        IdentityPoolId: appConfig.IdentityPoolId, // your identity pool id here
        Logins: {
          // Change the key below according to the specific region your user pool is in.
          [`cognito-idp.${appConfig.region}.amazonaws.com/${appConfig.UserPoolId}`]: session
            .getIdToken()
            .getJwtToken(),
        },
      };

      AWS.config.region = 'us-east-1';
      AWS.config.credentials = freshCredentials(credentialParams);

      // refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
      (AWS.config.credentials as AWS.Credentials).refresh((error: AWS.AWSError) => {
        if (error) {
          console.error(error);
          callback({
            name: 'AWSError',
            message: error.message,
          });
          return;
        }

        // Instantiate aws sdk service objects now that the credentials have been updated.
        // example: var s3 = new AWS.S3();
        callback();
      });
    },
    onFailure: error => {
      callback(error);
    },
  });
};

export const confirmPassword = (email: string, verificationCode: string, newPassword: string) => {
  const userData: ICognitoUserData = {
    Username: email,
    Pool: userPool(),
  };

  const cognitoUser = new CognitoUser(userData);
  return new Promise<void>((resolve, reject) => {
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onFailure(err) {
        reject(err);
      },
      onSuccess() {
        resolve();
      },
    });
  });
};

export const handleChangePassword = (
  oldPassword: string,
  newPassword: string,
  callback: (error?: AuthError) => void
) => {
  const cognitoUser = userPool().getCurrentUser();

  if (!cognitoUser) {
    callback({
      name: 'CurrentUser',
      message: 'Did not detect an active user.',
    });
    return;
  }

  cognitoUser.changePassword(oldPassword, newPassword, (error: Error, _) => {
    if (error) {
      callback(error);
      return;
    }

    callback();
  });
};

export const resendVerification = (email: string) => {
  const userData: ICognitoUserData = {
    Username: email,
    Pool: userPool(),
  };

  const cognitoUser = new CognitoUser(userData);
  cognitoUser.resendConfirmationCode((error: Error, _) => {
    if (error) {
      alert(error.message || JSON.stringify(error));
    }
  });
};

export const logout = (onCompletion: () => void = () => {}) => {
  const cognitoUser = userPool().getCurrentUser();
  cognitoUser?.signOut();
  onCompletion();
};

export const isBrowser = () => typeof window !== 'undefined';

type TemporaryLoginState =
  | { message: string; result: CognitoUserSession }
  | { message: string; error: Error };
interface TempLoginParams {
  username: string;
  temporaryPassword: string;
  newPassword: string;
  setTemporaryLoginState: (state: TemporaryLoginState) => void;
  setIsLoading?: (loading: boolean) => void;
  setIsTokenNotValid?: (tokenNotValid: boolean) => void;
}

export async function loginWithTemporaryPassword({
  username,
  temporaryPassword,
  newPassword,
  setTemporaryLoginState,
  setIsTokenNotValid,
  setIsLoading,
}: TempLoginParams): Promise<void> {
  const authDetails = new AuthenticationDetails({
    Username: username,
    Password: temporaryPassword,
  });

  const userPool = new CognitoUserPool({
    UserPoolId: appConfig.UserPoolId,
    ClientId: appConfig.ClientId,
  });

  const cognitoUser = new CognitoUser({
    Username: username,
    Pool: userPool,
  });

  cognitoUser.authenticateUser(authDetails, {
    onSuccess: result => {
      setTemporaryLoginState({ message: 'authenticationSuccess', result });
      if (setIsLoading) {
        setIsLoading(false);
      }
    },
    onFailure: error => {
      setTemporaryLoginState({ message: 'tokenExpired', error });
      if (
        error.message === 'Incorrect username or password.' ||
        error.message === 'Password attempts exceeded'
      ) {
        setIsTokenNotValid?.(true);
      }
      if (setIsLoading) {
        setIsLoading(false);
      }
    },
    newPasswordRequired: () => {
      cognitoUser.completeNewPasswordChallenge(
        newPassword,
        {},
        {
          onSuccess: result => {
            navigate('/');
            setTemporaryLoginState({ message: 'passwordUpdateSuccess', result });
            if (setIsLoading) {
              setIsLoading(false);
            }
          },
          onFailure: error => {
            setTemporaryLoginState({ message: 'passwordCreateError', error });
            if (setIsLoading) {
              setIsLoading(false);
            }
          },
        }
      );
    },
  });
}
