/* eslint-disable no-console */
import { useEffect, useState } from 'react';

import mixpanelBrowser from 'mixpanel-browser';

import { Company, MemberUser } from 'context/types';

import { getTotalPriceForLotArray } from './calculateTotalPriceBasedOnType';
import { mixpanelFake } from './mixpanelFake';

import type { Listing } from 'types';

const apiToken = process.env.MIXPANEL_PROJECT_TOKEN;

if (!apiToken) {
  console.error(
    'No Mixpanel API token found.  If this is production, please verify .env file used.'
  );
}

const debug = process.env.ENVIRONMENT !== 'production' && process.env.DEBUG === 'true';

const isMockMixpanel = !apiToken;
const mixpanel = isMockMixpanel ? mixpanelFake : mixpanelBrowser;

mixpanel.init(apiToken, { debug });

mixpanel.pageView = (pageName: string, properties?: Record<string, any>) => {
  mixpanel.track(`Page View: ${pageName}`, properties);
};

function usePageView(
  pageName: string,
  properties?: Record<string, any>,
  dependency: boolean = true
) {
  useEffect(() => {
    if (dependency) {
      mixpanel.pageView(pageName, properties);
    }
  }, [dependency]);
}

function trackEvent(eventTag: string, properties?: Record<string, any>) {
  mixpanel.track(`Event: ${eventTag}`, properties);
}

function trackTimeSpent(eventTag: string) {
  mixpanel.time_event(`Event: ${eventTag}`);
}

function trackTimeSpentProcess(eventTag: string, callback: () => void) {
  // Start tracking time spent
  trackTimeSpent(eventTag);

  const beforeUnload = 'beforeunload';

  const handleOnUnload = () => {
    callback();
    removeListener();
  };

  window.addEventListener(beforeUnload, handleOnUnload);

  const removeListener = () => {
    window.removeEventListener(beforeUnload, handleOnUnload);
  };

  return removeListener;
}

function trackLotBidEvent({
  event,
  pageName,
  modalContext,
  modalType,
  lotsSelected = [] as Listing[],
  ...rest
}) {
  const firstLot = lotsSelected[0] || {};
  trackEvent(event, {
    pageName,
    auctionType: 'Listing',
    modalContext,
    modalType,
    numOfLots: lotsSelected.length,
    tagNumber: firstLot.tagNumber,
    format: firstLot.format,
    subcategory: firstLot.subcategory,
    shapeType: firstLot.shapeType,
    category: firstLot.category,
    listingType: firstLot.listingType,
    pricePerLb: firstLot.pricePerLb,
    pricePerLinealFoot: firstLot.pricePerLinealFoot,
    totalPrice: getTotalPriceForLotArray(lotsSelected),
    ...rest,
  });
}

// Call only latest event if called repetitively
function useDebounceEvent(delay = 1000) {
  const [event, setEvent] = useState<null | {
    eventTag: string;
    properties?: Record<string, any>;
  }>(null);

  useEffect(() => {
    const eventTimeout = setTimeout(() => {
      if (event) {
        trackEvent(event.eventTag, event.properties);
      }
    }, delay);

    return () => clearTimeout(eventTimeout);
  }, [delay, event]);

  return (eventTag: string, properties?: Record<string, any>) => {
    setEvent({ eventTag, properties });
  };
}

type MemberEvent = {
  eventName: string;
  pageName: string;
  [key: string]: any;
};

export type LoggedInUser = Record<string, any> & {
  id?: string;
  firstName: string;
  lastName: string;
};

const trackMemberViewEvent = (
  event: MemberEvent,
  loggedInUser: LoggedInUser,
  member: MemberUser,
  company: Company
) => {
  const { id: userId, firstName, lastName } = loggedInUser;
  const { id: companyId, name: companyName } = company;
  const { id: memberUserId, firstName: memberFirstName, lastName: memberLastName } = member;
  const { eventName, pageName, ...rest } = event;

  trackEvent(eventName, {
    pageName,
    memberData: { id: memberUserId, firstName: memberFirstName, lastName: memberLastName },
    companyName: { id: companyId, companyName },
    loggedInUser: { id: userId, firstName, lastName },
    ...rest,
  });
};

export {
  isMockMixpanel,
  mixpanel,
  usePageView,
  trackEvent,
  trackLotBidEvent,
  useDebounceEvent,
  trackMemberViewEvent,
  trackTimeSpentProcess,
};
