import * as React from 'react';
import { Component } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';

import NotificationsPrompt from 'components/dashboard/NotificationsPrompt';

import DashboardService from '../services/DashboardService';
import { getUserAttributes } from '../utils/auth';

import { AuthContext } from './auth.context';

import type { DashContext as DashContextType } from './types';

// need to grab auction and bid data an always ensure availability because posting an auction from anywhere on the application
// needs to check for if a user owns the material or already has an auction posted
const initialState: DashContextType = {
  unviewedNotificationsCount: 0,
  updateAuction: () => {},
  toggleRegrabData: () => {},
  openDBModal: () => {},
  closeDBModal: () => {},
  openSuccessModal: () => {},
  closeSuccessModal: () => {},
  openNextStepsModal: () => {},
  closeNextStepsModal: () => {},
  openRejectAuctionModal: () => {},
  closeRejectAuctionModal: () => {},
  openLotBundleInfoModal: () => {},
  closeLotBundleInfoModal: () => {},
  setActiveDashboard: () => {},
  updateNotification: () => {},
  toggleMessageSnackbar: () => {},
  toggleNotificationMenu: () => {},
  fetchInitialNotifications: () => {},
  markAllNotificationAsViewed: () => {},
  activeDashboard: 'user',
  data: [],
  loadingTeamData: true,
  teamDashboardData: null,
  companyDashboardData: null,
  memberDetail: {},
  loadingUserListingData: true,
  totalLotCount: 0,
  notificationsLoaded: false,
  db_modalOpen: false,
  db_modalType: '',
  info_selectedAuction: '',
  accept_selectedAuction: '',
  reject_selectedAuction: '',
  db_selectedAuction: null,
  errorMessage: '',
  errorMessageVisible: false,
  errorMessageType: '',
  nextSteps: null,
  modalContext: '',
  regrabData: false,
  companyDataLoaded: false,
  unreadNotificationCount:
    typeof window !== 'undefined' && window.localStorage.getItem('unreadNotificationCount')
      ? JSON.parse(localStorage.getItem('unreadNotificationCount') || '0')
      : 0,
  isNotificationMenuOpen: false,
};

export const DashContext = React.createContext(initialState);

const dashboardService = new DashboardService();
export class DashProvider extends Component {
  state = {
    ...initialState,
  };

  async getUserLotData() {
    return await dashboardService.getListings();
  }

  setActiveDashboard = async activeDashboard => {
    await this.setState({
      activeDashboard,
    });
  };

  updateAuction = ({ type, callback, ...auctionData }) => {
    auctionData.bidType = type;

    dashboardService
      .updateAuction(auctionData)
      .then(({ data }) => {
        callback(data);
      })
      .catch(errors => {
        console.error(errors);
        callback({
          errors,
        });
      });
  };

  resetModalState = () => {
    this.setState({
      db_modalOpen: false,
      db_modalType: '',
      db_selectedAuction: null,
    });
  };

  openRejectAuctionModal = auctionObject => {
    this.setState({
      db_modalOpen: true,
      reject_selectedAuction: auctionObject,
    });
  };

  closeRejectAuctionModal = auctionObject => {
    this.setState({
      db_modalOpen: false,
      reject_selectedAuction: '',
    });
  };

  toggleRegrabData = val => {
    // maybe check for if toggle
    this.setState({ regrabData: val });
  };

  openNextStepsModal = auctionObject => {
    this.setState({
      db_modalOpen: true,
      nextSteps: auctionObject,
    });
  };

  closeNextStepsModal = () => {
    this.setState({
      db_modalOpen: false,
      db_selectedAuction: null,
      nextSteps: '',
    });
  };

  closeLotBundleInfoModal = () => {
    this.setState({
      db_modalOpen: false,
      info_selectedAuction: '',
    });
  };

  openLotBundleInfoModal = (auctionObject, modalContext) => {
    this.setState({
      db_modalOpen: true,
      info_selectedAuction: auctionObject,
      modalContext,
    });
  };

  closeDBModal = () => {
    this.setState({
      db_modalOpen: false,
      db_modalType: '',
      db_selectedAuction: null,
      modalContext: '',
    });
  };

  openDBModal = (type, auctionObject, label, modalContext) => {
    this.setState({
      db_modalOpen: true,
      db_modalType: type,
      db_selectedAuction: auctionObject,
      db_modalLabel: label,
      modalContext,
    });
  };

  openSuccessModal = (type, acceptAuctionObject) => {
    this.setState({
      db_modalOpen: true,
      db_modalType: type,
      accept_selectedAuction: acceptAuctionObject,
    });
  };

  closeSuccessModal = acceptAuctionObject => {
    this.setState({
      db_modalOpen: false,
      db_modalType: '',
      // does below need to be reset???? why not????
      accept_selectedAuction: '',
    });
  };

  toggleMessageSnackbar = (errorState, message, type) => {
    if (typeof message !== 'string') {
      return;
    }

    this.setState({
      errorMessageVisible: errorState,
      errorMessage: message,
      errorMessageType: type,
    });
  };

  markAllNotificationAsViewed = (updateType, callBack) => {
    dashboardService.markAllNotificationAsViewed(updateType).then(() => {
      if (!updateType) {
        this.setState({ unviewedNotificationsCount: 0 });
      }
      if (callBack) {
        callBack();
      }
    });
  };

  fetchInitialNotifications = async viewType => {
    this.setState({ notificationsLoaded: false });
    try {
      const {
        data: { unreadNotificationsCount, unviewedNotificationsCount },
      } = await dashboardService.getUserNotifications(viewType);
      // set state
      this.setState(
        {
          notificationsLoaded: true,
          unreadNotificationCount: unreadNotificationsCount,
          unviewedNotificationsCount,
        },
        () => {
          localStorage.setItem(
            'unreadNotificationCount',
            JSON.stringify(this.state.unreadNotificationCount)
          );
        }
      );
    } catch (err) {
      console.error(err);
    }
  };

  toggleNotificationMenu = () => {
    this.setState({
      isNotificationMenuOpen: !this.state.isNotificationMenuOpen,
    });
  };

  updateNotification = (updateOneAsRead: boolean = true) => {
    const { unreadNotificationCount } = this.state;
    this.setState(
      {
        unreadNotificationCount:
          updateOneAsRead && unreadNotificationCount > 0 ? unreadNotificationCount - 1 : 0,
      },
      () => {
        localStorage.setItem('unreadNotificationCount', JSON.stringify(unreadNotificationCount));
      }
    );
  };

  initialFetch = () => {
    getUserAttributes(({ error, attributes }) => {
      if (error) {
        return;
      }
      const emailAttribute = attributes?.find(attr => attr.Name === 'email');
      if (!emailAttribute) {
        return;
      }

      // on load do the user fetch to grab all user info, lot info, auction info
      this.setState({ email: emailAttribute.Value });
      this.fetchInitialNotifications('all');
    });
  };

  static contextType = AuthContext;
  componentDidMount() {
    this.initialFetch();
  }

  componentDidUpdate(prevProps, prevState) {
    const { regrabData } = this.state;

    if (regrabData) {
      this.toggleRegrabData(false);
      this.initialFetch();
    }
  }

  exposedMethods = {
    updateAuction: this.updateAuction,
    updateNotification: this.updateNotification,
    toggleRegrabData: this.toggleRegrabData,
    toggleMessageSnackbar: this.toggleMessageSnackbar,
    toggleNotificationMenu: this.toggleNotificationMenu,
    openNextStepsModal: this.openNextStepsModal,
    closeNextStepsModal: this.closeNextStepsModal,
    openRejectAuctionModal: this.openRejectAuctionModal,
    closeRejectAuctionModal: this.closeRejectAuctionModal,
    openSuccessModal: this.openSuccessModal,
    closeSuccessModal: this.closeSuccessModal,
    openDBModal: this.openDBModal,
    closeDBModal: this.closeDBModal,
    openLotBundleInfoModal: this.openLotBundleInfoModal,
    closeLotBundleInfoModal: this.closeLotBundleInfoModal,
    markAllNotificationAsViewed: this.markAllNotificationAsViewed,
    setActiveDashboard: this.setActiveDashboard,
  };

  render() {
    const { errorMessageVisible, errorMessage } = this.state;

    return (
      <DashContext.Provider value={{ ...this.state, ...this.exposedMethods }}>
        {this.props.children}
        <NotificationsPrompt />
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={errorMessageVisible}
          autoHideDuration={6000}
          onClose={() => this.toggleMessageSnackbar(false, '', 'success')}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{errorMessage}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => this.toggleMessageSnackbar(false, '', 'success')}
              size="large"
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </DashContext.Provider>
    );
  }
}

DashProvider.contextType = AuthContext;
