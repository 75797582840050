import * as React from 'react';
import { PropsWithChildren } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { makeStyles } from 'tss-react/mui';

import { BlueSteelSpinner } from 'components/library/BlueSteelSpinner';

const useStyles = makeStyles()({
  loaderOverlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10000000000,
    opacity: 1,
  },
});

const OldLoader = () => (
  <div data-testid="loading-spinner" className="ph4 pv5">
    <CircularProgress style={{ color: '#5D0549', margin: '0 auto', display: 'block' }} />
  </div>
);

export default OldLoader;

export const ComponentLoader = ({
  isLoading,
  children,
}: PropsWithChildren<{ isLoading: boolean }>) => {
  return isLoading ? <BlueSteelSpinner /> : <React.Fragment>{children}</React.Fragment>;
};

export const LoadingOverlay = () => {
  const { classes } = useStyles();
  return (
    <div data-testid="loading-overlay" className={classes.loaderOverlay}>
      <CircularProgress style={{ color: '#fff' }} />
    </div>
  );
};

export const PageLoader = () => {
  return (
    <Grid container height="100vh" justifyContent="center" alignContent="center">
      <BlueSteelSpinner data-testid="loading-spinner" showLabel={true} />
    </Grid>
  );
};
