import * as React from 'react';

import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { sharedStyles } from './shared';

import type { TypographyProps } from './types';

type Size = 'small' | 'medium' | 'large';

type BodyProps = TypographyProps & {
  size?: Size;
};

const FONT_SIZE_MAP: Record<Size, number> = {
  small: 14,
  medium: 16,
  large: 18,
};

const LINE_HEIGHT_MAP: Record<Size, number> = {
  small: 17.5,
  medium: 20,
  large: 22.5,
};

const bodyStyles = (size: Size = 'medium') => ({
  fontSize: FONT_SIZE_MAP[size],
  lineHeight: `${LINE_HEIGHT_MAP[size]}px`,
});

const useStyles = makeStyles<BodyProps>()((_, { weight, size, ...rest }) => {
  return {
    root: {
      ...sharedStyles({ weight, ...rest }),
      ...bodyStyles(size),
    },
  };
});

const Body = React.forwardRef(({ className, ...rest }: BodyProps, ref) => {
  const { classes, cx } = useStyles(rest);
  // @ts-ignore
  return <Typography variant="body1" className={cx(classes.root, className)} ref={ref} {...rest} />;
});

export default Body;
