import { createContext, useReducer, useContext, useMemo, useEffect } from 'react';

import { useAuthContext } from 'context/hooks';
import { Company as CompanyType, CompanyLocation } from 'context/types';
import UserService from 'services/UserService';
import CompanyService from 'utils/services/company.service';
type CompanyState = {
  statusCompany: string;
  statusNewLocation: string;
  companyData: CompanyType;
  locationData: CompanyLocation;
  accountsList: string[];
  rowsPerPage: number;
  locationError: string;
  message: string;
};

const newUserService = new UserService();
const CompanyStateContext = createContext<CompanyState | undefined>(undefined);
const CompanyDispatchContext = createContext<React.Dispatch<any> | undefined>(undefined);
const ActionType = {
  DATA_LOADING: 'DATA_LOADING',
  DATA_ERROR: 'DATA_ERROR',
  NEW_LOCATION_ERROR: 'NEW_LOCATION_ERROR',
  UPDATE_LOCATION_ERROR: 'UPDATE_LOCATION_ERROR',
  COMPANY_LOADED: 'COMPANY_LOADED',
  ACCOUNTS_LOADED: 'ACCOUNTS_LOADED',
  COMPANY_CREATED: 'COMPANY_CREATED',
  COMPANY_UPDATED: 'COMPANY_UPDATED',
  LOCATION_ADDED: 'LOCATION_ADDED',
  LOCATION_DELETED: 'LOCATION_DELETED',
  LOCATION_UPDATED: 'LOCATION_UPDATED',
  COMPANY_DELETED: 'COMPANY_DELETED',
  SHOW_INFO: 'SHOW_INFO',
  SUCCESS_PUBLISH_COMPANY: 'SUCCESS_PUBLISH_COMPANY',
  FAIL_PUBLISH_COMPANY: 'FAIL_PUBLISH_COMPANY',
  FAIL_INVITING_MEMBER: 'FAIL_INVITING_MEMBER',
  NEW_LOCATION_LOADING: 'NEW_LOCATION_LOADING',
  PRODUCT_UPDATE_CREATED: 'PRODUCT_UPDATE_CREATED',
};
const CompanyStatus = {
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  SUCCESS_PUBLISH_COMPANY: 'SUCCESS_PUBLISH_COMPANY',
  ERROR: 'ERROR',
  SHOW_INFO: 'SHOW_INFO',
  FAIL_INVITE: 'FAIL_INVITE',
  NEW_LOCATION_LOADING: 'NEW_LOCATION_LOADING',
};

const LocationStatus = {
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

const CompanyStatusType = {
  active: 'active',
  unpublished: 'unpublished',
};

function contextReducer(state, action) {
  switch (action.type) {
    case ActionType.COMPANY_LOADED:
    case ActionType.COMPANY_CREATED:
    case ActionType.COMPANY_UPDATED: {
      return {
        ...state,
        statusCompany: CompanyStatus.SUCCESS,
        companyData: action.companyData,
      };
    }
    case ActionType.ACCOUNTS_LOADED: {
      return {
        ...state,
        statusCompany: CompanyStatus.SUCCESS,
        accountsList: action.accountsList,
      };
    }
    case ActionType.LOCATION_ADDED: {
      return {
        ...state,
        statusNewLocation: CompanyStatus.SUCCESS,
        locationData: action.location,
      };
    }
    case ActionType.PRODUCT_UPDATE_CREATED:
    case ActionType.LOCATION_DELETED: {
      return {
        ...state,
        statusCompany: CompanyStatus.SUCCESS,
      };
    }
    case ActionType.COMPANY_DELETED: {
      const companies = state.accountsList.filter(company => company.id !== action.companyId);

      return {
        ...state,
        statusCompany: CompanyStatus.SUCCESS,
        accountsList: companies,
      };
    }
    case ActionType.LOCATION_UPDATED: {
      return {
        ...state,
        statusCompany: CompanyStatus.SUCCESS,
        locationData: action.location,
      };
    }
    case ActionType.SUCCESS_PUBLISH_COMPANY: {
      return {
        ...state,
        statusCompany: CompanyStatus.SUCCESS_PUBLISH_COMPANY,
        message: 'sucessPublishCompany',
      };
    }
    case ActionType.FAIL_PUBLISH_COMPANY: {
      return {
        ...state,
        statusCompany: CompanyStatus.ERROR,
        message: 'failPublishCompany',
      };
    }
    case ActionType.DATA_LOADING: {
      return {
        ...state,
        statusCompany: CompanyStatus.LOADING,
      };
    }
    case ActionType.NEW_LOCATION_LOADING: {
      return {
        ...state,
        statusNewLocation: LocationStatus.LOADING,
      };
    }
    case ActionType.SHOW_INFO: {
      return {
        ...state,
        statusCompany: CompanyStatus.SHOW_INFO,
      };
    }
    case ActionType.DATA_ERROR: {
      return {
        ...state,
        statusCompany: CompanyStatus.ERROR,
      };
    }
    case ActionType.NEW_LOCATION_ERROR: {
      return {
        ...state,
        statusNewLocation: CompanyStatus.ERROR,
      };
    }
    case ActionType.UPDATE_LOCATION_ERROR: {
      return {
        ...state,
        statusNewLocation: CompanyStatus.ERROR,
        statusCompany: CompanyStatus.ERROR,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

const initialState = {
  statusCompany: CompanyStatus.LOADING,
  statusNewLocation: '',
  companyData: {},
  locationData: {},
  accountsList: [],
  rowsPerPage: 10,
  locationError: '',
  message: '',
};

function CompanyContextProvider({ children }) {
  const [state, dispatch] = useReducer(contextReducer, initialState);
  const { company } = useAuthContext();

  useEffect(() => {
    if (company?.id) {
      getCompanyData(dispatch, company.id, null);
    }
  }, [company?.id]);

  return (
    <CompanyStateContext.Provider value={state}>
      <CompanyDispatchContext.Provider value={dispatch}>{children}</CompanyDispatchContext.Provider>
    </CompanyStateContext.Provider>
  );
}

function useCompanyState() {
  const context = useContext(CompanyStateContext);
  if (context === undefined) {
    throw new Error('useCompanyState must be used within a CompanyContextProvider');
  }
  return context;
}

function useCompanyDispatch() {
  const context = useContext(CompanyDispatchContext);
  if (context === undefined) {
    throw new Error('useCompanyDispatch must be used within a CompanyContextProvider');
  }
  return context;
}

async function getCompanyEmptyData(dispatch) {
  dispatch({ type: ActionType.COMPANY_LOADED, companyData: initialState.companyData });
}

async function getCompanyData(dispatch, companyId, role) {
  dispatch({ type: ActionType.DATA_LOADING });
  try {
    const [companyData, roleData] = await Promise.all([
      CompanyService.getCompany(companyId),
      role ? newUserService.getUsersByRole(role) : {},
    ]);
    const data = { ...companyData.data, ...(roleData?.data || {}) };
    dispatch({ type: ActionType.COMPANY_LOADED, companyData: data });
    return data;
  } catch (error) {
    dispatch({ type: ActionType.DATA_ERROR, error });
    return error;
  }
}

async function getMyAccountsData(dispatch, params) {
  dispatch({ type: ActionType.DATA_LOADING });
  try {
    const response = await CompanyService.getMyAccounts(params);
    dispatch({ type: ActionType.ACCOUNTS_LOADED, accountsList: response });
  } catch (error) {
    dispatch({ type: ActionType.DATA_ERROR, error });
  }
}

async function postCompanyData(dispatch, body) {
  dispatch({ type: ActionType.DATA_LOADING });
  try {
    const { data } = await CompanyService.postCompany(body);
    dispatch({ type: ActionType.COMPANY_CREATED, companyData: data });
    return data;
  } catch (error) {
    dispatch({ type: ActionType.DATA_ERROR, error });
    return error;
  }
}

async function putCompanyData(dispatch, companyId, body) {
  dispatch({ type: ActionType.DATA_LOADING });
  try {
    const { data } = await CompanyService.putCompany(companyId, body);
    dispatch({ type: ActionType.COMPANY_UPDATED, companyData: data });
  } catch (error) {
    dispatch({ type: ActionType.DATA_ERROR, error });
  }
}

async function postLocationData(dispatch, companyId, body) {
  dispatch({ type: ActionType.NEW_LOCATION_LOADING });
  try {
    const allLocations = await CompanyService.postLocation(companyId, body);
    dispatch({ type: ActionType.LOCATION_ADDED });
    return allLocations;
  } catch (error) {
    dispatch({ type: ActionType.NEW_LOCATION_ERROR, error });
    throw error;
  }
}

async function putLocationData(dispatch, companyId, locationId, body) {
  dispatch({ type: ActionType.DATA_LOADING });
  try {
    await CompanyService.putLocation(companyId, locationId, body);
    dispatch({ type: ActionType.LOCATION_UPDATED, location: body });
  } catch (error) {
    dispatch({ type: ActionType.UPDATE_LOCATION_ERROR, error });
    throw error;
  }
}

async function deleteLocationData(dispatch, companyId, locationId) {
  dispatch({ type: ActionType.DATA_LOADING });
  try {
    const response = await CompanyService.deleteLocation(companyId, locationId);
    dispatch({ type: ActionType.LOCATION_DELETED, id: locationId });
    return response;
  } catch (error) {
    dispatch({ type: ActionType.DATA_ERROR, error });
    return false;
  }
}

async function postProductUpdateData(dispatch, companyId, data) {
  dispatch({ type: ActionType.DATA_LOADING });
  try {
    const { id } = await CompanyService.postProductUpdate(companyId, data);
    dispatch({ type: ActionType.PRODUCT_UPDATE_CREATED, id });
  } catch (error) {
    dispatch({ type: ActionType.DATA_ERROR, error });
  }
}

async function deleteCompany(dispatch, companyId) {
  dispatch({ type: ActionType.DATA_LOADING });
  try {
    await CompanyService.deleteCompany(companyId);
    dispatch({ type: ActionType.COMPANY_DELETED, id: companyId });
  } catch (error) {
    dispatch({ type: ActionType.DATA_ERROR, error });
  }
}

async function publishCompany(dispatch, companyId) {
  dispatch({ type: ActionType.DATA_LOADING });
  try {
    await CompanyService.publishCompany(companyId).then(() => {
      dispatch({ type: ActionType.SUCCESS_PUBLISH_COMPANY });
    });
  } catch (error) {
    dispatch({ type: ActionType.FAIL_PUBLISH_COMPANY, error });
    return false;
  }
}

function useCompanyActions() {
  const dispatch = useCompanyDispatch();
  return useMemo(
    () => ({
      getCompanyEmptyData: getCompanyEmptyData.bind(null, dispatch),
      getCompanyData: getCompanyData.bind(null, dispatch),
      getMyAccountsData: getMyAccountsData.bind(null, dispatch),
      postCompanyData: postCompanyData.bind(null, dispatch),
      putCompanyData: putCompanyData.bind(null, dispatch),
      postLocationData: postLocationData.bind(null, dispatch),
      putLocationData: putLocationData.bind(null, dispatch),
      deleteLocationData: deleteLocationData.bind(null, dispatch),
      postProductUpdateData: postProductUpdateData.bind(null, dispatch),
      deleteCompany: deleteCompany.bind(null, dispatch),
      publishCompany: publishCompany.bind(null, dispatch),
    }),
    []
  );
}
const exportedForTesting = {
  postProductUpdateData,
};

export {
  CompanyContextProvider,
  useCompanyState,
  useCompanyDispatch,
  getCompanyData,
  publishCompany,
  CompanyStatus,
  LocationStatus,
  CompanyStatusType,
  useCompanyActions,
  exportedForTesting,
};
