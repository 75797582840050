const hideDeprecationWarnings = () => {
  const errorFn = global.console.error;
  const warnFn = global.console.warn;

  const isIgnoredMui = (msg: string, ignoreList: string[]): boolean => {
    return (
      typeof msg === 'string' &&
      msg.includes('MUI:') &&
      ignoreList.some(ignoredSubstring => msg.includes(ignoredSubstring))
    );
  };

  const ignoreForTesting = [
    'inside a test was not wrapped in act',
    'Each child in a list should have a unique "key" prop.',
    'react-i18next',
    'Encountered two children with the same key',
    'To locate the bad setState() call inside',
    'A props object containing a "key" prop is being spread into JSX',
    'Failed prop type: Invalid prop',
    'getPriceTypePerUnit',
    'A component is changing an uncontrolled input to be controlled.',
    "component doesn't accept a Fragment as a child",
    'Failed prop type',
  ];

  const isIgnoredForTesting = (msg: string) => {
    return (
      typeof msg === 'string' &&
      ignoreForTesting.some(ignoredSubstring => msg.includes(ignoredSubstring))
    );
  };

  const ignoreListError = [];
  const ignoreListWarn = ['adaptV4Theme() is deprecated.'];

  global.console.error = (...args) => {
    const [msg] = args;
    if (!isIgnoredForTesting(msg) && !isIgnoredMui(msg, ignoreListError)) {
      errorFn(...args);
    }
  };

  global.console.warn = (...args) => {
    const [msg] = args;
    if (!isIgnoredForTesting(msg) && !isIgnoredMui(msg, ignoreListWarn)) {
      warnFn(...args);
    }
  };
};

if (!process.env.DEBUG && process.env.NODE_ENV!.includes('test')) {
  hideDeprecationWarnings();
}
