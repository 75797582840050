// Heavily borrowed from
// https://github.com/cra-template/pwa/blob/fc9613279681a06606f90514926b8078db629ec6/packages/cra-template-pwa/template/src/serviceWorkerRegistration.js

// Copyright 2020 Google LLC

// Permission is hereby granted, free of charge, to any person obtaining a copy
// of this software and associated documentation files (the "Software"), to deal
// in the Software without restriction, including without limitation the rights
// to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
// copies of the Software, and to permit persons to whom the Software is
// furnished to do so, subject to the following conditions:

// The above copyright notice and this permission notice shall be included in
// all copies or substantial portions of the Software.

// THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
// IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
// FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
// AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
// LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN
// THE SOFTWARE.

/* eslint-disable no-console */

import * as Sentry from '@sentry/react';

// This lets the app load faster on subsequent visits in production, and gives
// the application (the potential for) offline capabilities. However, it also means
// that users  will only see deployed updates on subsequent visit to a page
// (in contrast with previous state: only after a manual refresh) since previously
// cached resources are updated in the background.

const LOG_PREFIX = '[registerServiceWorker]';

type ConsoleLoggingLevel = keyof typeof console & ('debug' | 'log' | 'warn' | 'error');
const logAtLevel = (level: ConsoleLoggingLevel) => {
  return (...args: any[]) => {
    console[level](LOG_PREFIX, ...args);
  };
};

const logger = {
  info: logAtLevel('log'),
  warn: logAtLevel('warn'),
  error: logAtLevel('error'),
  debug: logAtLevel('debug'),
};

const { hostname, origin } = window.location;

// [::1] is the IPv6 localhost address.
// 127.0.0.0/8 are considered localhost for IPv4.
const IPV4_LOCALHOST_REGEX = /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/;
const isLocalhost =
  ['localhost', '[::1]'].includes(hostname) || !!hostname.match(IPV4_LOCALHOST_REGEX);

// localhost meant to be used with nginx:watch command and a local single-spa instance
// in order to avoid CORS issues.
//
// ./marketplace will hit a specific path in the S3 bucket due to how our domains
// are configured.
const PUBLIC_URL = process.env.IS_LOCAL
  ? 'http://localhost:8080/marketplace'
  : `${origin}/marketplace`;

// Default for WorkboxWebpackPlugin.GenerateSW `swDest` option --
// this should match whatever is configured in webpack.config.js
const swUrl = `${PUBLIC_URL}/service-worker.js`;

type ServiceWorkerConfig = {
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

export const register = (config?: ServiceWorkerConfig) => {
  if (!isLocalhost && process.env.IS_LOCAL) {
    logger.debug('Not registering service worker in local environment.');
    return;
  }

  if (!window.isSecureContext && !isLocalhost && process.env.NODE_ENV === 'production') {
    logger.info(
      'The current page is not being served over HTTPS so service workers can not be used. See https://w3c.github.io/ServiceWorker/#secure-context'
    );
    return;
  }

  if (process.env.NODE_ENV !== 'production' || !('serviceWorker' in navigator)) {
    logger.warn(`Service worker is not supported in ${process.env.NODE_ENV} or this browser.`);
    return;
  }

  window.addEventListener('load', async () => {
    if (!isLocalhost && !process.env.IS_LOCAL) {
      await registerValidSW(config);
      return;
    }

    // This is running on localhost. Let's check if a service worker still exists or not.
    await checkValidServiceWorker(config);

    await navigator.serviceWorker.ready;
    logger.debug('Service worker ready');
    logger.info(
      'This web app is being served cache-first by a service worker. See https://developer.chrome.com/docs/workbox/service-worker-overview/'
    );
  });
};

const registerValidSW = async (config?: ServiceWorkerConfig) => {
  logger.debug('Registering service worker...');

  try {
    const registration = await navigator.serviceWorker.register(swUrl, { scope: '/' });

    const { active, installing, waiting } = registration;
    logger.debug('Service worker registration successful. Registration: ', {
      active,
      installing,
      waiting,
    });

    registration.onupdatefound = () => {
      logger.debug('Service worker update found.');

      const installingWorker = registration.installing;

      if (installingWorker == null) {
        logger.debug('No service worker found.');
        return;
      }

      installingWorker.onstatechange = () => {
        logger.debug(`New service worker state changed to ${installingWorker.state}`);
        if (installingWorker.state !== 'installed') {
          return;
        }

        logger.debug(`Service worker registration successful with scope: ${registration.scope}`);
        logger.debug(
          `Existing service worker state: ${navigator.serviceWorker.controller?.state ?? 'none'}`
        );

        // truthy when the service worker is controlling the page,
        // i.e. there is an active or activating service worker already
        // see https://developer.mozilla.org/en-US/docs/Web/API/ServiceWorkerContainer/controller
        if (navigator.serviceWorker.controller) {
          // If skipWaiting and claimClients are true, the new service worker will
          // take over the page immediately and start pre-caching
          logger.debug('New content is available and is being updated...');

          config?.onUpdate?.(registration);
          return;
        }

        // At this point, everything has been precached.
        logger.debug('Content is cached for offline use.');
        config?.onSuccess?.(registration);
      };
    };

    // We have seen instances where the service worker update is detected, downloaded,
    // but not installed and stuck waiting. (registration.onupdatedfound is not properly executed)
    // This is an attempt to fix.
    if (registration.waiting && !registration.installing) {
      logger.debug('Activating waiting service worker.');
      config?.onUpdate?.(registration);
    }
  } catch (error) {
    Sentry.captureException(error);
    logger.error('Error during service worker registration:', error);
  }
};

const checkValidServiceWorker = async (config?: ServiceWorkerConfig) => {
  // Check if the service worker can be found. If it can't, reload the page.
  logger.info(`Checking service worker exists at ${swUrl}...`);
  try {
    const { headers, status } = await fetch(swUrl, {
      headers: { 'Service-Worker': 'script' },
    });

    // Ensure service worker exists, and that we really are getting a JS file.
    if (status !== 404 && headers.get('content-type')?.includes('javascript')) {
      // Service worker found. Proceed as normal.
      await registerValidSW(config);
      return;
    }

    // No service worker found. Probably a different app. Reload the page.
    logger.debug('Found no service worker. Reloading page...');
    const registration = await navigator.serviceWorker.ready;
    await registration.unregister();
    window.location.reload();
  } catch {
    logger.info('No internet connection found. App is running in offline mode.');
  }
};

export const unregister = async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.ready;
      registration.unregister();
    } catch (error) {
      Sentry.captureException(error);
      logger.error(error.message);
    }
  }
};
