import bundles from '@reibus/reibus-core-utils/dist/src/i18n/bundles';
import { createInstance } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

const NAMESPACES = [
  'accept-reject-bids',
  'account-settings',
  'admin',
  'algolia',
  'common',
  'company',
  'component-library',
  'dashboard-overview',
  'estimate',
  'pl-rfq-form',
  'forgot-password',
  'listing-details',
  'listings-management',
  'listings',
  'locations',
  'login',
  'material-form',
  'member',
  'modals',
  'my-accounts',
  'my-listing-bids',
  'my-listing-offers',
  'my-listings',
  'my-rfqs',
  'notifications',
  'rfq-pages',
  'trade-credit-widget',
  'enums',
  'units',
];

function cloneKeys(source, defaultValue = 'XXXXXXXXXX') {
  if (typeof source === 'string') {
    return defaultValue;
  }

  return Object.entries(source).reduce((memo, [key, value]) => {
    if (typeof value === 'string') {
      memo[key] = defaultValue;
    }

    if (typeof value === 'object') {
      memo[key] = Array.isArray(value)
        ? value.map(original => cloneKeys(original, defaultValue))
        : cloneKeys(value, defaultValue);
    }

    return memo;
  }, {});
}

const BUNDLES_LANGUAGE_MAP = {
  en: 'en-US',
  qa: 'en-US',
  'qa-XX': 'en-US',
};

const MODULES_LANGUAGE_MAP = {
  qa: 'en-US',
  'qa-XX': 'en-US',
};

export const i18nSSR = createInstance();
const i18nSSRReady = i18nSSR
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(
    resourcesToBackend((language, namespace, callback) => {
      let resources;
      if (['enums', 'units'].includes(namespace)) {
        const resolvedLanguage = BUNDLES_LANGUAGE_MAP[language] || language;
        const bundle = bundles[resolvedLanguage];
        if (!bundle) {
          callback(
            new Error(`Could not find utils bundle for resolved language ${resolvedLanguage}`),
            null
          );
          return;
        }
        resources = bundle[namespace];
      } else {
        const resolvedLanguage = MODULES_LANGUAGE_MAP[language] || language;
        try {
          resources = require(`../../locales/${resolvedLanguage}/${namespace}.json`);
        } catch (error) {
          callback(error, null);
          return;
        }
      }

      if (
        (language === 'qa-XX' || language === 'qa') &&
        process.env.ENVIRONMENT !== 'production' &&
        resources
      ) {
        resources = cloneKeys(resources);
      }

      callback(null, resources);
    })
  )
  .init({
    fallbackLng: 'en-US',
    ns: NAMESPACES,
    defaultNS: 'common',
    debug: process.env.ENVIRONMENT !== 'production' && process.env.DEBUG === 'true',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
    keySeparator: '.',
  });

export const isLocaleEn = (preferredLocale: string = '') => preferredLocale.startsWith('en');

export const loadI18n = async () => {
  await i18nSSRReady;
  return i18nSSR;
};
