import { useCallback } from 'react';

import { useLocation, navigate } from '@reach/router';

type Config = {
  redirectOnLogin?: boolean;
};

const useForceLogin = ({ redirectOnLogin = true }: Config = {}) => {
  const { search, pathname } = useLocation();

  return useCallback(
    () =>
      redirectOnLogin
        ? navigate('/login', {
            state: { from: `${pathname}${search ?? ''}` },
          })
        : navigate('/login'),
    [search, pathname, redirectOnLogin]
  );
};

export default useForceLogin;
