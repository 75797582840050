import { TypographyProps, Weight, Color } from './types';

const WEIGHT_MAP: Record<Weight, number> = {
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

const COLOR_MAP: Record<Color, string> = {
  default: '#212224',
};

const getFontWeight = (weight: Weight) => WEIGHT_MAP[weight];
const getColor = (color: Color) => COLOR_MAP[color] || color;

export const sharedStyles = ({ weight = 'regular', color = 'default' }: TypographyProps) => ({
  padding: 0,
  margin: 0,
  fontFamily: 'Montserrat',
  color: getColor(color),
  fontWeight: getFontWeight(weight),
});
