import * as React from 'react';
import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useAuthContext } from 'context/hooks';
import { updatePassword } from 'utils/auth';
import MemberService from 'utils/services/members.service';

const MemberStateContext = React.createContext();
const MemberDispatchContext = React.createContext();

const ActionType = {
  START_GET: 'START_GET',
  SUCCESS_GET: 'SUCCESS_GET',
  ERROR_GET: 'ERROR_GET',
  START_GET_TYPES: 'START_GET_TYPES',
  SUCCESS_GET_TYPES: 'SUCCESS_GET_TYPES',
  ERROR_GET_TYPES: 'ERROR_GET_TYPES',
  START_CHANGE_USER: 'START_CHANGE_USER',
  SUCCESS_CHANGE_USER: 'SUCCESS_CHANGE_USER',
  ERROR_CHANGE_USER: 'ERROR_CHANGE_USER',
  START_GET_EMAILS: 'START_GET_EMAILS',
  SUCCESS_GET_EMAILS: 'SUCCESS_GET_EMAILS',
  ERROR_GET_EMAILS: 'ERROR_GET_EMAILS',
  START_UPDATE_MEMBER: 'START_UPDATE_MEMBER',
  SUCCESS_UPDATE_MEMBER: 'SUCCESS_UPDATE_MEMBER',
  ERROR_UPDATE_MEMBER: 'ERROR_UPDATE_MEMBER',
  START_REMOVE_MEMBER: 'START_REMOVE_MEMBER',
  SUCCESS_REMOVE_MEMBER: 'SUCCESS_REMOVE_MEMBER',
  ERROR_REMOVE_MEMBER: 'ERROR_REMOVE_MEMBER',
  START_GET_DETAIL: 'START_GET_DETAIL',
  SUCCESS_GET_DETAIL: 'SUCCESS_GET_DETAIL',
  ERROR_GET_DETAIL: 'ERROR_GET_DETAIL',
  FINISH_FLOW: 'FINISH_FLOW',
  DATA_LOADING: 'DATA_LOADING',
  DATA_ERROR: 'DATA_ERROR',
  SUCCESS_PUBLISH_COMPANY: 'SUCCESS_PUBLISH_COMPANY',
  START_GET_USER: 'START_GET_USER',
  SUCCESS_GET_USER: 'SUCCESS_GET_USER',
  ERROR_GET_USER: 'ERROR_GET_USER',
};

const StatusType = {
  SHOW_INFO: 0,
  LOADING: 1,
  SUCCESS: 2,
  ERROR: 3,
};

function makeTranslatedContextReducer(t) {
  function contextReducer(state, action) {
    switch (action.type) {
      case ActionType.START_GET:
      case ActionType.START_GET_TYPES:
      case ActionType.START_GET_EMAILS:
      case ActionType.START_UPDATE_MEMBER:
      case ActionType.START_GET_DETAIL:
      case ActionType.START_CHANGE_USER:
      case ActionType.START_REMOVE_MEMBER:
      case ActionType.START_GET_USER:
      case ActionType.DATA_LOADING: {
        return {
          ...state,
          status: StatusType.LOADING,
        };
      }
      case ActionType.SUCCESS_GET: {
        return {
          ...state,
          status: StatusType.SHOW_INFO,
          memberList: action.memberList ? action.memberList : [],
          hasMoreMembers: action.memberList ? action.memberList.length >= state.rowsPerPage : false,
        };
      }
      case ActionType.SUCCESS_GET_SELECTED_COMPANY_MEMBERS: {
        return {
          ...state,
          status: StatusType.SHOW_INFO,
          selectedCompanyMembers: action.selectedCompanyMembers
            ? action.selectedCompanyMembers
            : [],
          hasMoreMembers: action.selectedCompanyMembers
            ? action.selectedCompanyMembers.length >= state.rowsPerPage
            : false,
        };
      }
      case ActionType.SUCCESS_GET_TYPES: {
        return {
          ...state,
          rolesType: action.rolesType,
          regionsType: action.regionsType,
        };
      }
      case ActionType.SUCCESS_GET_EMAILS: {
        return {
          ...state,
          status: StatusType.SHOW_INFO,
          memberEmails: action.memberEmails,
        };
      }
      case ActionType.SUCCESS_GET_DETAIL: {
        return {
          ...state,
          status: StatusType.SHOW_INFO,
          memberDetail: action.member,
        };
      }
      case ActionType.SUCCESS_CHANGE_USER: {
        return {
          ...state,
          status: StatusType.SUCCESS,
          message: action.message,
        };
      }
      case ActionType.SUCCESS_PUBLISH_COMPANY: {
        return {
          ...state,
          status: StatusType.SUCCESS,
          message: action.message,
        };
      }
      case ActionType.SUCCESS_UPDATE_MEMBER: {
        return {
          ...state,
          status: StatusType.SUCCESS,
          message: action.message,
          memberDetail: action.member ? action.member : state.member,
        };
      }
      case ActionType.SUCCESS_REMOVE_MEMBER: {
        return {
          ...state,
          status: StatusType.SUCCESS,
        };
      }
      case ActionType.ERROR_GET:
      case ActionType.ERROR_GET_TYPES:
      case ActionType.ERROR_GET_EMAILS:
      case ActionType.ERROR_GET_DETAIL:
      case ActionType.ERROR_CHANGE_USER:
      case ActionType.ERROR_UPDATE_MEMBER:
      case ActionType.ERROR_REMOVE_MEMBER:
      case ActionType.ERROR_GET_USER:
      case ActionType.DATA_ERROR: {
        return {
          ...state,
          status: StatusType.ERROR,
          message: action.error?.message,
        };
      }
      case ActionType.FINISH_FLOW: {
        return {
          ...state,
          status: StatusType.SHOW_INFO,
        };
      }
      case ActionType.SUCCESS_GET_USER: {
        return {
          ...state,
          status: StatusType.SHOW_INFO,
        };
      }
      default: {
        throw new Error(`Unhandled action type: ${action.type}`);
      }
    }
  }
  return contextReducer;
}

const initialState = {
  status: StatusType.SHOW_INFO,
  memberList: [],
  selectedCompanyMembers: [],
  memberEmails: [],
  hasMoreMembers: false,
  memberDetail: {},
  rowsPerPage: 5,
  rolesType: [],
  regionsType: [],
};

function MemberContextProvider({ children }) {
  const { t } = useTranslation('member');
  const [state, dispatch] = React.useReducer(makeTranslatedContextReducer(t), initialState);

  const { cognitoUser: { username } = {} } = useAuthContext();

  useEffect(() => {
    if (username) {
      getRolesAndRegions(dispatch);
    }
  }, [username]);

  return (
    <MemberStateContext.Provider value={state}>
      <MemberDispatchContext.Provider value={dispatch}>{children}</MemberDispatchContext.Provider>
    </MemberStateContext.Provider>
  );
}

function useMemberState() {
  const context = React.useContext(MemberStateContext);
  if (context === undefined) {
    throw new Error('useMemberState must be used within a MemberContextProvider');
  }
  return context;
}

function useMemberDispatch() {
  const context = React.useContext(MemberDispatchContext);
  if (context === undefined) {
    throw new Error('useMemberDispatch must be used within a MemberContextProvider');
  }
  return context;
}

async function getRolesAndRegions(dispatch) {
  try {
    const { roles, regions } = await MemberService.getRolesAndRegions();
    dispatch({ type: ActionType.SUCCESS_GET_TYPES, rolesType: roles, regionsType: regions });
  } catch (error) {
    console.error(error);
  }
}

async function getMemberList(dispatch, companyId, params) {
  dispatch({ type: ActionType.START_GET });
  try {
    const memberList = await MemberService.getMembers(companyId, params);
    dispatch({ type: ActionType.SUCCESS_GET, memberList });
  } catch (error) {
    dispatch({ type: ActionType.ERROR_GET, error, memberList: [] });
  }
}

async function getselectedCompanyMembers(dispatch, companyId, params) {
  dispatch({ type: ActionType.START_GET });
  try {
    const selectedCompanyMembers = await MemberService.getMembers(companyId, params);
    dispatch({ type: ActionType.SUCCESS_GET_SELECTED_COMPANY_MEMBERS, selectedCompanyMembers });
  } catch (error) {
    dispatch({ type: ActionType.ERROR_GET, error, selectedCompanyMembers: [] });
  }
}

async function getMemberEmails(dispatch, companyId) {
  try {
    const memberEmails = await MemberService.getMembersEmails(companyId);
    dispatch({ type: ActionType.SUCCESS_GET_EMAILS, memberEmails });
  } catch (error) {
    dispatch({ type: ActionType.ERROR_GET_EMAILS, error });
  }
}

async function getMemberDetail(dispatch, memberId) {
  dispatch({ type: ActionType.START_GET_DETAIL });
  try {
    let memberInfo = await MemberService.getMemberDetail(memberId);
    memberInfo = { ...memberInfo, id: memberId };

    /* const [firstName, lastName] = memberInfo.name.split(" ")
    memberInfo.firstName = firstName
    memberInfo.lastName = lastName */

    dispatch({ type: ActionType.SUCCESS_GET_DETAIL, member: memberInfo });
  } catch (error) {
    dispatch({ type: ActionType.ERROR_GET_DETAIL, error });
  }
}

async function updateMember(dispatch, currentUserInfo, newMemberInfo) {
  dispatch({ type: ActionType.START_UPDATE_MEMBER });
  try {
    await MemberService.updatePrismaMember(newMemberInfo).then(res => {
      console.warn(res);
    });

    dispatch({
      type: ActionType.SUCCESS_UPDATE_MEMBER,
      message: 'Updated Successfully.',
      member: {
        ...currentUserInfo,
        ...newMemberInfo,
      },
    });
  } catch (error) {
    console.error(error);
    dispatch({ type: ActionType.ERROR_UPDATE_MEMBER, error: { message: error } });
  }
}

async function updateCurrentUser(dispatch, newMemberInfo) {
  dispatch({ type: ActionType.START_UPDATE_MEMBER });
  try {
    const attributeList = [
      {
        Name: 'name',
        Value: newMemberInfo.firstName + ' ' + newMemberInfo.lastName,
      },
      {
        Name: 'given_name',
        Value: newMemberInfo.firstName,
      },
      {
        Name: 'family_name',
        Value: newMemberInfo.lastName,
      },
    ];

    await MemberService.updateAwsMember(attributeList);
    await MemberService.updatePrismaMember(newMemberInfo);
    dispatch({
      type: ActionType.SUCCESS_UPDATE_MEMBER,
      message: 'Updated Successfully.',
      member: newMemberInfo,
    });
  } catch (error) {
    console.error(error);
    dispatch({ type: ActionType.ERROR_UPDATE_MEMBER, error });
  }
}

async function removePasswordValidation(dispatch) {
  dispatch({ type: ActionType.START_CHANGE_USER });
  try {
    const attributeList = [
      {
        Name: 'custom:reset_password',
        Value: 'false',
      },
    ];

    await MemberService.updateAwsMember(attributeList);
    dispatch({ type: ActionType.SUCCESS_CHANGE_USER, message: 'cognito User updated' });
  } catch (error) {
    console.error(error);
    dispatch({ type: ActionType.ERROR_CHANGE_USER, error });
  }
}

async function updateMemberRole(dispatch, memberInfo, roleInfo) {
  dispatch({ type: ActionType.START_UPDATE_MEMBER });
  try {
    await MemberService.updateMemberRoleInfo(memberInfo, roleInfo);
    const updatedMember = {
      ...memberInfo,
      userRole: roleInfo,
    };
    dispatch({
      type: ActionType.SUCCESS_UPDATE_MEMBER,
      message: 'Updated Successfully.',
      member: updatedMember,
    });
  } catch (error) {
    dispatch({ type: ActionType.ERROR_UPDATE_MEMBER, error });
  }
}

async function changeUserPassword(dispatch, oldPassword, newPassword) {
  dispatch({ type: ActionType.START_CHANGE_USER });
  try {
    await updatePassword(oldPassword, newPassword);
    dispatch({ type: ActionType.SUCCESS_CHANGE_USER, message: 'Password changed' });
  } catch (error) {
    dispatch({ type: ActionType.ERROR_CHANGE_USER, error });
    return 'error';
  }
}

async function changeUserNotificationsSettings(dispatch, notifSettings, email) {
  dispatch({ type: ActionType.START_CHANGE_USER });

  try {
    await MemberService.submitAccountSettingsToPrisma({
      ...notifSettings,
      email,
    });
    dispatch({ type: ActionType.SUCCESS_CHANGE_USER, message: 'Settings updated successfully' });
  } catch (error) {
    dispatch({ type: ActionType.ERROR_CHANGE_USER, error });
  }
}

async function deleteMember(dispatch, memberToDelete, memberForTranfer) {
  dispatch({ type: ActionType.START_REMOVE_MEMBER });
  try {
    await MemberService.removeMember(memberToDelete.email, memberForTranfer);
    dispatch({ type: ActionType.SUCCESS_REMOVE_MEMBER });
  } catch (error) {
    dispatch({ type: ActionType.ERROR_REMOVE_MEMBER, error });
  }
}

async function getCurrentUser(dispatch) {
  dispatch({ type: ActionType.START_GET_USER });
  try {
    const response = await MemberService.getCurrentUser();
    dispatch({ type: ActionType.SUCCESS_GET_USER });
    return response;
  } catch (error) {
    dispatch({ type: ActionType.ERROR_GET_USER, error });
  }
}

export {
  MemberContextProvider,
  useMemberState,
  useMemberDispatch,
  getMemberList,
  getselectedCompanyMembers,
  updateCurrentUser,
  removePasswordValidation,
  updateMember,
  deleteMember,
  getMemberDetail,
  getMemberEmails,
  changeUserPassword,
  changeUserNotificationsSettings,
  getRolesAndRegions,
  updateMemberRole,
  getCurrentUser,
  StatusType,
  ActionType,
};

export const exportedForTesting = {
  makeTranslatedContextReducer,
};
