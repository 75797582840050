import { lazy, Suspense, useEffect } from 'react';

import { LicenseInfo } from '@mui/x-license-pro';
import { Router, Redirect } from '@reach/router';
import { useUserPreferences } from '@reibus/frontend-utility';
import { useTranslation } from 'react-i18next';

import { PageLoader } from 'components/helpers/loader';
import { useAuthContext } from 'context/hooks';
import 'css/about.css';
import 'css/app.css';
import 'css/cart.css';
import 'css/checkout.css';
import 'css/company-profile.scss';
import 'css/dashboard.css';
import 'css/delete-user-modal.scss';
import 'css/edit-bid.css';
import 'css/expandedTab.css';
import 'css/favorites.css';
import 'css/filterMenu.css';
import 'css/filters.css';
import 'css/header.css';
import 'css/helpers/alerts.css';
import 'css/helpers/forms.css';
import 'css/helpers/tachyons/tachyons.css';
import 'css/imageSelector.scss';
import 'css/imageSelectorWithZoom.scss';
import 'css/info-modal.scss';
import 'css/layout.css';
import 'css/listing.css';
import 'css/listingShow.css';
import 'css/locations.scss';
import 'css/member-profile.scss';
import 'css/memberListPage.scss';
import 'css/negotiation.scss';
import 'css/new-rfq-form.css';
import 'css/newDashboardLayout.scss';
import 'css/newHeader.scss';
import 'css/reibus-modal.scss';
import 'css/request-access.scss';
import 'css/reset-password.scss';
import 'css/rfq.css';
import 'css/rfqDealBoard.css';
import 'css/select.css';
import 'css/success.css';
import { trackEvent } from 'utils/mixpanel';

const RenderNull = () => null;

// These should start and end in '/'
const WILDCARD_REDIRECT_PATHS = ['/requests-for-quote/', '/listings/'];
const REIBUSU_PATHS = {
  '/metal-gauge-charts': '/metal-gauge-charts',
  '/coil-color-charts': '/metal-color-charts',
  '/calculators': '/calculator',
};
const DEPRECATED_PATHS = {
  '/': '/login',
  '/insights': '/login',
  '/forgotPassword': '/forgot-password',
  '/app/dashboard/activity': '/app/dashboard/activity/offers',
  '/app/user/add-user-rfq': '/app/requests-for-quote/create',
  '/app/requests-for-quote/new': '/app/requests-for-quote/create',
  '/app/dashboard/add-product-line': '/app/user/product-lines/create',
};
const REDIRECT_PATHS = Object.keys(DEPRECATED_PATHS);
LicenseInfo.setLicenseKey(process.env.MUI_LICENSE_KEY as string);

const Admin = lazy(() => import('components/pages/Admin'));
const App = lazy(() => import('components/pages/app'));
const Show = lazy(() => import('components/pages/show'));
const Page404 = lazy(() => import('components/pages/404'));
const ForgotPassword = lazy(() => import('components/pages/forgot-password'));
const Login = lazy(() => import('components/pages/login'));
const Logout = lazy(() => import('components/pages/logout'));
const SetPassword = lazy(() => import('components/pages/set-password'));
const Unsubscribe = lazy(() => import('components/pages/unsubscribe'));
const Verification = lazy(() => import('components/pages/verification'));

const Index = ({ location }) => {
  const { id: userId } = useAuthContext();
  const { i18n } = useTranslation();
  const { preferredLocale } = useUserPreferences();

  const DEPRECATED_COMPOSED_PATHS = {
    '/app/dashboard/product-lines': `/app/user/${userId}/product-lines`,
  };
  const DEPRECATED_ROUTES = { ...DEPRECATED_PATHS, ...DEPRECATED_COMPOSED_PATHS };

  const filteredRedirectPaths = [
    ...REDIRECT_PATHS,
    ...Object.keys(DEPRECATED_COMPOSED_PATHS),
  ].filter(path => {
    // Can only redirect when user is logged in
    return userId || !['/app/dashboard/product-lines'].includes(path);
  });

  useEffect(() => {
    if (
      WILDCARD_REDIRECT_PATHS.some(redirectPath => location?.pathname?.startsWith(redirectPath))
    ) {
      const toPath = `/app${window.location.pathname}`;
      trackEvent('Redirect', { from: location.pathname, to: toPath, marketing: false });
      window.location.pathname = toPath;
      return;
    }

    const reibusuPathFound = Object.entries(REIBUSU_PATHS).find(([legacyPath]) =>
      location?.pathname?.startsWith(legacyPath)
    );

    if (reibusuPathFound) {
      const toPath = `https://www.reibus.com/reibusu${reibusuPathFound[1]}`;
      trackEvent('Redirect', { from: location.pathname, to: toPath, marketing: true });
      window.location.assign(toPath);
    }

    if (filteredRedirectPaths.includes(location.pathname)) {
      trackEvent('Redirect', {
        from: location.pathname,
        to: DEPRECATED_ROUTES[location.pathname],
        marketing: false,
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    i18n.changeLanguage(preferredLocale);
  }, [preferredLocale]);

  return (
    <Suspense fallback={<PageLoader />}>
      <Router primary={false}>
        {filteredRedirectPaths.map(key => (
          <Redirect key={key} from={key} to={DEPRECATED_ROUTES[key]} noThrow />
        ))}
        <Admin path="/admin/*" />
        <Show path="/show/*" />
        <Unsubscribe path="/unsubscribe/:notification" />
        <Page404 path="/*" />

        <Login path="/login" />
        <Logout path="/logout" />
        <Verification path="/verification" />
        <ForgotPassword path="/forgot-password" />
        <SetPassword path="/set-password" />
        <App path="/app/*" />

        {WILDCARD_REDIRECT_PATHS.map(path => (
          <RenderNull key={path} path={`${path}*`} />
        ))}
        {Object.keys(REIBUSU_PATHS).map(path => (
          <RenderNull key={path} path={`${path}*`} />
        ))}
        <Page404 path="/404" default />
      </Router>
    </Suspense>
  );
};

export default Index;
