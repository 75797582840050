import { useCurrentUser } from '@reibus/frontend-utility';
import { useMutation } from 'react-query';

import axios from 'services/axios';

type PromptType = 'MATCH_NOTIFICATIONS';

export const useUpdateUserPrompt = () => {
  const { refetch: refetchUser } = useCurrentUser();
  return useMutation(async (prompt: PromptType) => {
    const { data } = await axios
      .patch(
        `${process.env.API_URL}/users/activity`,
        {
          userPrompt: { prompt, lastSeen: new Date().toISOString() },
        },
        { params: { activityType: 'USER_PROMPT' } }
      )
      .then(() => refetchUser());

    return data;
  });
};
