import { ProductLinesData } from 'components/dashboard/types';

import BaseService from './BaseService';

import type { Company } from 'context/types';

class UserService extends BaseService {
  getProductSuppliedByUserId(userId: string) {
    return this.getServiceInstance().get<{ products: ProductLinesData[] }>(
      `${this.urls.GET_USER_PRODUCT_LINE(userId)}`
    );
  }

  getExchangeRates() {
    return this.getServiceInstance().get(this.urls.GET_EXCHANGE_RATE);
  }

  getProductsSupplied() {
    return this.getServiceInstance().get(this.urls.BASE_CRUD_PRODUCT_LINE);
  }

  async createProductLine(productLine) {
    const { data } = await this.getServiceInstance().post(
      this.urls.BASE_CRUD_PRODUCT_LINE,
      productLine
    );
    return data;
  }

  async updateProductLine(productLine) {
    const { data } = await this.getServiceInstance().put(
      this.urls.BASE_CRUD_PRODUCT_LINE,
      productLine
    );
    return data;
  }

  deleteProductLine(id: string, plOwnerId?: string) {
    const productData = {
      id,
      plOwnerId,
    };
    return this.getServiceInstance().delete(this.urls.BASE_CRUD_PRODUCT_LINE, {
      data: productData,
    });
  }

  markNotificationAsRead(id, updateType) {
    const notificationData = {
      id,
      updateType,
    };
    return this.getServiceInstance().put(this.urls.USER_NOTIFICATIONS, {
      data: notificationData,
    });
  }

  changeNotificationViewType(userId, notificationView) {
    return this.getServiceInstance().put(`${this.urls.UPDATE_PRISMA_USER}/${userId}`, {
      notificationView,
    });
  }

  getCompanies(queryString) {
    return this.getServiceInstance().get(`${this.urls.GET_COMPANIES}?${queryString}`);
  }

  getErvCompanies(queryString) {
    return this.getServiceInstance().get(`${this.urls.GET_ERV_COMPANIES}`);
  }

  createCompany(companyData) {
    return this.getServiceInstance().post(this.urls.BASE_CRUD_COMPANY, companyData);
  }

  getCompany(companyId: string) {
    return this.getServiceInstance().get<Company>(`${this.urls.BASE_CRUD_COMPANY}/${companyId}`);
  }

  deleteCompany(companyId) {
    return this.getServiceInstance().delete(`${this.urls.BASE_CRUD_COMPANY}/${companyId}`);
  }

  updateCompany(companyId, companyData) {
    return this.getServiceInstance().put(
      `${this.urls.BASE_CRUD_COMPANY}/${companyId}`,
      companyData
    );
  }

  updateCompanyProductUpdates(companyId, productUpdateDaya) {
    return this.getServiceInstance().post(
      `${this.urls.BASE_CRUD_COMPANY}/${companyId}/product-updates`,
      productUpdateDaya
    );
  }

  publishCompany(companyId) {
    return this.getServiceInstance().post(`${this.urls.PUBLISH_COMPANY}/${companyId}/publish`);
  }

  createCompanyLocation(companyId, locationData) {
    return this.getServiceInstance().post(
      `${this.urls.BASE_CRUD_COMPANY}/${companyId}/location`,
      locationData
    );
  }

  deleteCompanyLocation(companyId, locationId) {
    return this.getServiceInstance().delete(
      `${this.urls.BASE_CRUD_COMPANY}/${companyId}/location/${locationId}`
    );
  }

  updateCompanyLocation(companyId, locationId, locationData) {
    return this.getServiceInstance().put(
      `${this.urls.BASE_CRUD_COMPANY}/${companyId}/location/${locationId}`,
      locationData
    );
  }

  // TMSCompany
  getTMSCompaniesByName(companyName) {
    return this.getServiceInstance().get(
      this.replaceUrlParams(this.urls.GET_TMS_COMPANIES, { ':searchValue': companyName })
    );
  }

  getEnumByName(enumName) {
    return this.getServiceInstance().get(`${this.urls.ENUM_BY_NAME}${enumName}`);
  }

  getCompanyMembers(companyId, order) {
    const urlParams = {
      ':companyId': companyId,
      ':createdAt_DESC': order,
    };

    return this.getServiceInstance().get(
      this.replaceUrlParams(this.urls.GET_COMPANY_MEMBERS, urlParams)
    );
  }

  getErvCompanyMembers(companyId, order) {
    const urlParams = {
      ':companyId': companyId,
      ':createdAt_DESC': order,
    };

    return this.getServiceInstance().get(
      this.replaceUrlParams(this.urls.GET_ERV_COMPANY_MEMBERS, urlParams)
    );
  }

  getCompanyMembersEmails(companyId) {
    const urlParams = {
      ':companyId': companyId,
    };

    return this.getServiceInstance().get(
      this.replaceUrlParams(this.urls.GET_COMPANY_MEMBERS_EMAILS, urlParams)
    );
  }

  getMemberDetail(memberId) {
    const urlParams = {
      ':memberId': memberId,
    };

    return this.getServiceInstance().get(this.replaceUrlParams(this.urls.GET_USER, urlParams));
  }

  getPrismaUsers() {
    return this.getServiceInstance().get(this.urls.UPDATE_PRISMA_USER);
  }

  updatePrismaMember(userId, userInfo) {
    return this.getServiceInstance().put(`${this.urls.UPDATE_PRISMA_USER}/${userId}`, userInfo);
  }

  deletePrismaMember(memberInfo) {
    return this.getServiceInstance().delete(this.urls.UPDATE_PRISMA_USER, {
      data: memberInfo,
    });
  }

  getRegions() {
    return this.getServiceInstance().get(this.urls.GET_REGIONS);
  }

  getRoles() {
    return this.getServiceInstance().get(this.urls.GET_ROLES);
  }

  getUsersByRole(role) {
    const urlParams = {
      ':role': role,
    };

    return this.getServiceInstance().get(
      this.replaceUrlParams(this.urls.GET_USERS_BY_ROLE, urlParams)
    );
  }

  getMemberRoles(memberId) {
    const urlParams = {
      ':memberId': memberId,
    };

    return this.getServiceInstance().get(
      `${this.replaceUrlParams(this.urls.BASE_CRUD_MEMBER_ROLES, urlParams)}`
    );
  }

  addMemberRole(memberId, roleId, roleData) {
    const urlParams = {
      ':memberId': memberId,
      ':roleId': roleId,
    };

    return this.getServiceInstance().post(
      `${this.replaceUrlParams(this.urls.BASE_CRUD_MEMBER_ROLE, urlParams)}/${roleId}`,
      roleData
    );
  }

  updateMemberRole(memberId, roleId, roleData) {
    const urlParams = {
      ':memberId': memberId,
      ':roleId': roleId,
    };

    return this.getServiceInstance().put(
      `${this.replaceUrlParams(this.urls.BASE_CRUD_MEMBER_ROLE, urlParams)}/${roleId}`,
      roleData
    );
  }

  deleteMemberRole(memberId, roleId) {
    const urlParams = {
      ':memberId': memberId,
      ':roleId': roleId,
    };

    return this.getServiceInstance().delete(
      `${this.replaceUrlParams(this.urls.BASE_CRUD_MEMBER_ROLE, urlParams)}/${roleId}`
    );
  }

  updateAccountSettings(accountData) {
    return this.getServiceInstance().put(this.urls.UPDATE_PRISMA_USER, accountData);
  }
}

export default UserService;
