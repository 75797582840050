import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  root: {
    '& .MuiButton-startIcon > *:nth-of-type(1)': {
      fontSize: '1rem',
    },
    minWidth: '7.5rem',
    height: '2.625rem',
    borderRadius: '8px',
    textTransform: 'none',
    fontWeight: 700,
    padding: '0rem 2rem',
  },
  primary: {
    backgroundColor: '#2D6CCB',
    color: '#ffffff',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#215199',
      boxShadow:
        '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    },
    '&:focus': {
      borderRadius: '0.5rem',
      boxShadow: 'none',
      outline: '3px solid rgba(33, 81, 153, 0.20)',
      backgroundColor: '#2D6CCB',
    },
    '&:active': {
      backgroundColor: '#215199',
      boxShadow: 'none',
      outline: 'none',
    },
  },
  secondary: {
    backgroundColor: 'transparent',
    color: '#2D6CCB',
    border: '2px solid #2D6CCB',
    '&:hover': {
      backgroundColor: '#EAF2FF',
      boxShadow:
        '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
      border: '2px solid #EAF2FF',
    },
    '&:focus': {
      borderRadius: '0.5rem',
      outline: '3px solid rgba(33, 81, 153, 0.20)',
      background: '#FFF',
    },
    '&:active': {
      backgroundColor: '#EAF2FF',
      border: '2px solid #EAF2FF',
      outline: 'none',
      boxShadow: 'none',
    },
    '&.Mui-disabled': {
      border: '2px solid #EDEEF0',
    },
  },
  text: {
    color: '#2D6CCB',
  },
  disabled: {
    backgroundColor: '#EDEEF0',
    color: '#868A8E !important',
    cursor: 'not-allowed',
    '&:hover': {
      backgroundColor: '#EDEEF0',
      color: '#868A8E !important',
      boxShadow: 'none',
    },
  },
}));

const MUI_VARIANT_MAP = {
  primary: 'contained',
  secondary: 'outlined',
  text: 'text',
};

type ButtonProps = {
  label: string | React.ReactNode;
  disabled?: boolean;
  variant?: 'primary' | 'secondary' | 'text';
};

// What is wrong with Button V1? secondary variant has blue background instead of transparent
const Button = ({
  label,
  variant = 'primary',
  ...rest
}: ButtonProps & Omit<MuiButtonProps, 'variant' | 'color'>) => {
  const { classes } = useStyles();
  return (
    <MuiButton
      {...rest}
      variant={MUI_VARIANT_MAP[variant] as 'text' | 'contained' | 'outlined'}
      classes={{
        root: classes.root,
        contained: classes.primary,
        outlined: classes.secondary,
        text: classes.text,
        disabled: classes.disabled,
      }}
      disableRipple
      disableFocusRipple
    >
      {label}
    </MuiButton>
  );
};

export default Button;
