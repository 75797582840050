import BaseService from './BaseService';
import { GetRfqAuctionsInsightsResponse } from './GetRfqAuctionsInsights';
import { GetListingAuctions } from './hooks/dashboard/useListingAuctions';
import { GetAuctionResponse } from './ListingAuctionsService';

import type {
  GetUserRFQResponse,
  CreateRfqThirdPartyApprovalsPayload,
  GetRfqThirdPartyApprovalsResponse,
} from './types';
import type { AxiosResponse } from 'axios';

const NEW_BID_STATUSES = [
  'REJECTED_BY_BIDDER',
  'REJECTED_BY_OWNER',
  'ACCEPT_OFFER',
  'ACCEPT_COUNTER',
  'COUNTER',
  'OFFER',
] as const;

type Address = {
  address1?: string;
  address2?: string;
  city?: string;
  region?: string;
  postalCode?: string;
  country: string;
};

type AddBidToAuctionPayload = {
  auctionStatus: string;
  bidBody?: string | null;
  bidUnitQuantity?: number;
  bidExpirationDate: string;
  bidPrice?: number;
  bidStatus: (typeof NEW_BID_STATUSES)[number];
  auctionID: string;
  rfqID: string;
  bidID: string;
  bidDeliveryDate?: string;
  rejectReason: string | null;
  rejectReasonOther: string | null;
  shipmentOrigin?: Address;
};

class DashboardService extends BaseService {
  private static INSTANCE = new DashboardService();

  getSummary() {
    return this.getServiceInstance().get(`${this.urls.GET_DASHBOARD_SUMMARY}`);
  }

  getListingRequest(id) {
    return this.getServiceInstance().get(`${this.urls.GET_LISTING}/${id}`);
  }

  getMaterialRequests() {
    return this.getServiceInstance().get(this.urls.BASE_CRUD_RFQ);
  }

  getMaterialRequest(id) {
    return this.getServiceInstance().get(`${this.urls.BASE_CRUD_RFQ}/${id}`);
  }

  createMaterialRequest(rfqData) {
    return this.getServiceInstance().post(this.urls.BASE_CRUD_RFQ, rfqData);
  }

  updateMaterialRequest(rfqData) {
    return this.getServiceInstance().put(this.urls.BASE_CRUD_RFQ, rfqData);
  }

  getFollowedRFQs() {
    return this.getServiceInstance().get(this.urls.GET_FOLLOWED_RFQS);
  }

  followRFQ(objectId) {
    return this.getServiceInstance().post(`${this.urls.CREATE_FOLLOW_RFQ}${objectId}`);
  }

  getListings() {
    return this.getServiceInstance().get(`${this.urls.BASE_CRUD_USER_LISTING}`);
  }

  getListingByType(listingType) {
    return this.getServiceInstance().get(`${this.urls.BASE_CRUD_USER_LOTS}/${listingType}`);
  }

  deleteListing(lotId) {
    return this.getServiceInstance().delete(`${this.urls.BASE_CRUD_USER_LOTS}/${lotId}`);
  }

  deleteRFQ(objectID: string, reasonDeleted: string, ownerEmail?: string) {
    const body = {
      objectID,
      ownerEmail,
      reasonDeleted,
    };
    return this.getServiceInstance().delete(`${this.urls.DELETE_RFQ}`, {
      data: body,
    });
  }

  unFollowRFQ(objectId) {
    return this.getServiceInstance().delete(`${this.urls.DELETE_FOLLOW_RFQ}${objectId}`);
  }

  getUserRFQ(id: string, userId?: string): Promise<AxiosResponse<GetUserRFQResponse>> {
    const queryParams = userId ? `?userId=${userId}` : '';
    return this.getServiceInstance().get(`${this.urls.GET_USER_RFQ}${id}${queryParams}`);
  }

  getUserRFQS() {
    return this.getServiceInstance().get(`${this.urls.GET_USER_RFQS}`);
  }

  getRFQAuctions(id: string, userId?: string): Promise<AxiosResponse<GetAuctionResponse>> {
    const queryParams = userId ? `&userId=${userId}` : '';
    return this.getServiceInstance().get(`${this.urls.GET_RFQ_AUCTIONS}?rfqId=${id}${queryParams}`);
  }

  getRfqAuctionsInsights(
    rfqId: string,
    externalUserId?: string
  ): Promise<AxiosResponse<GetRfqAuctionsInsightsResponse>> {
    const queryParams = externalUserId ? `?externalUserId=${externalUserId}` : '';
    return this.getServiceInstance().get(
      `${this.replaceUrlParams(this.urls.GET_RFQ_AUCTIONS_INSIGHTS, {
        ':rfqId': rfqId,
      })}${queryParams}`
    );
  }

  getUserSellingRFQs() {
    return this.getServiceInstance().get(`${this.urls.GET_USER_SELLING_RFQS}`);
  }

  getSubCategories() {
    return this.getServiceInstance().get(`${this.urls.GET_SUB_CATEGORIES}`);
  }

  updateSellingRFQConfig(config) {
    return this.getServiceInstance().put(`${this.urls.UPDATE_SELLING_BOARD_CONFIG}`, config);
  }

  getRFQHiddenData(id) {
    return this.getServiceInstance().get(`${this.urls.GET_RFQ_HIDDEN_DATA}${id}`);
  }

  updateQuoteBid(bidData) {
    return this.getServiceInstance().put(
      this.replaceUrlParams(this.urls.UPDATE_QUOTE_BID, {
        ':auctionID': 'unknown', // HACK ALERT: we don't currently have a good way to get the auction ID but this isn't currently used by the backend
        ':bidID': bidData.id,
      }),
      bidData
    );
  }

  rejectBid(rejectData: AddBidToAuctionPayload) {
    return this.getServiceInstance().post(
      this.replaceUrlParams(this.urls.POST_BID_FOR_QUOTE_AUCTION, {
        ':auctionID': rejectData.auctionID,
      }),
      rejectData
    );
  }

  updateSourcing(payload) {
    return this.getServiceInstance().put(
      `${this.urls.UPDATE_SOURCING}${payload.objectID}`,
      payload
    );
  }

  createNote(noteData) {
    return this.getServiceInstance().post(
      this.replaceUrlParams(this.urls.BASE_CRUD_NOTE, {
        ':id': noteData.quoteId,
      }),
      noteData
    );
  }

  deleteNote(noteData) {
    return this.getServiceInstance().delete(
      `${this.replaceUrlParams(this.urls.BASE_CRUD_NOTE, {
        ':id': noteData.quoteId,
      })}/${noteData.noteId}`,
      { data: noteData }
    );
  }

  updateNote(noteData) {
    return this.getServiceInstance().put(
      `${this.replaceUrlParams(this.urls.BASE_CRUD_NOTE, {
        ':id': noteData.quoteId,
      })}/${noteData.noteId}`,
      noteData
    );
  }

  acceptOffer(acceptedOffer: AddBidToAuctionPayload) {
    return this.getServiceInstance().post(
      this.replaceUrlParams(this.urls.POST_BID_FOR_QUOTE_AUCTION, {
        ':auctionID': acceptedOffer.auctionID,
      }),
      acceptedOffer
    );
  }

  counterOffer(counterOffer: AddBidToAuctionPayload) {
    return this.getServiceInstance().post(
      this.replaceUrlParams(this.urls.POST_BID_FOR_QUOTE_AUCTION, {
        ':auctionID': counterOffer.auctionID,
      }),
      counterOffer
    );
  }

  getAdminListing(queryParams) {
    return this.getServiceInstance().get(`${this.urls.GET_ADMIN_LISTING}${queryParams}`);
  }

  getShowListing(tagNumber) {
    return this.getServiceInstance().get(`${this.urls.GET_LISTING}?tagNumber=${tagNumber}`);
  }

  createAuctionBid(bidData) {
    return this.getServiceInstance().post(`${this.urls.CREATE_AUCTION_BID}`, bidData);
  }

  addBidToAuctions(bidData) {
    return this.getServiceInstance().post(this.urls.CREATE_QUOTE_AUCTION, bidData);
  }

  getListingAuctions(params?: URLSearchParams) {
    return this.getServiceInstance().get<GetListingAuctions.Response>(this.urls.LISTING_AUCTIONS, {
      params,
    });
  }

  updateAuction(auctionData) {
    return this.getServiceInstance().put(
      this.replaceUrlParams(this.urls.LISTINGS_AUCTIONS_BY_ID, { ':id': auctionData.auctionID }),
      auctionData
    );
  }

  updatePrismaUser(userData) {
    return this.getServiceInstance().put(`${this.urls.UPDATE_PRISMA_USER}`, userData);
  }

  getUserNotifications(viewType: string, page: number = 1) {
    return this.getServiceInstance().get(
      `${this.urls.GET_USER_NOTIFICATIONS}?&view=${viewType}&page=${page}`
    );
  }

  getAuctionDeadline(listingOrRfqIds, listingOrRfq, auctionID = '') {
    const ids = Array.isArray(listingOrRfqIds) ? listingOrRfqIds : [listingOrRfqIds];
    return this.getServiceInstance()
      .post(`${this.urls.GET_AUCTION_DEADLINE}`, {
        ids,
        name: listingOrRfq,
        auctionID,
      })
      .then(response => {
        if (response.status === 200) {
          return Number.parseInt(response.data, 10);
        }
      });
  }

  // Overview Cards
  getListingsSummaryCard(params) {
    return this.getServiceInstance().post(this.urls.GET_LISTINGS_SUMMARY_CARD, params);
  }

  getRfqsSummaryCard(params) {
    return this.getServiceInstance().post(this.urls.GET_RFQS_SUMMARY_CARD, params);
  }

  getListingsBreakdownCard(params) {
    return this.getServiceInstance().post(this.urls.GET_LISTINGS_BREAKDOWN_CARD, params);
  }

  getRFQsSubmittedCard(params) {
    return this.getServiceInstance().post(this.urls.GET_RFQS_SUBMITTED_CARD, params);
  }

  getMyProductLinesCard(params) {
    const searchParams = new URLSearchParams(params);
    return this.getServiceInstance().get(`${this.urls.GET_MY_PRODUCT_LINES_CARD}?${searchParams}`);
  }

  getProductLineMatchesCard(params) {
    return this.getServiceInstance().post(this.urls.GET_PRODUCT_LINE_MATCHES_CARD, params);
  }

  getListingBidsCard(params) {
    return this.getServiceInstance().post(this.urls.GET_LISTING_BIDS_CARD, params);
  }

  getRfqBidsCard(params) {
    return this.getServiceInstance().post(this.urls.GET_MATERIAL_REQUESTS_BIDS_CARD, params);
  }

  getRfqThirdPartyApprovals(
    rfqId: string
  ): Promise<AxiosResponse<GetRfqThirdPartyApprovalsResponse>> {
    return this.getServiceInstance().get(
      this.replaceUrlParams(this.urls.LIST_RFQ_3RD_PARTY_APPROVAL_REQUESTS, { ':rfqID': rfqId })
    );
  }

  createRfqThirdPartyApprovals(rfqId: string, payload: CreateRfqThirdPartyApprovalsPayload) {
    return this.getServiceInstance().post(
      this.replaceUrlParams(this.urls.LIST_RFQ_3RD_PARTY_APPROVAL_REQUESTS, { ':rfqId': rfqId }),
      payload
    );
  }

  deleteRfqThirdPartyApprovals(rfqId: string, approvalRequestId: string) {
    return this.getServiceInstance().delete(
      this.replaceUrlParams(this.urls.DETAIL_RFQ_3RD_PARTY_APPROVAL_REQUEST, {
        ':rfqId': rfqId,
        ':approvalRequestId': approvalRequestId,
      })
    );
  }

  markAllNotificationAsViewed(updateType = 'markAllAsViewed') {
    const notificationData = {
      updateType,
      platform: 'WEB',
    };

    return this.getServiceInstance().put(this.urls.USER_NOTIFICATIONS, {
      data: notificationData,
    });
  }

  public static getInstance() {
    return this.INSTANCE;
  }
}

export default DashboardService;
